import React from 'react';
import Navbar from '../../elements/navbar/Navbar';
import TripForm from '../home/components/trip-form/TripForm';
import Fleet from '../home/components/fleet/Fleet';
import Footer from '../../elements/footer/Footer';
import { Content } from './styles';

const TaxiTransfer = () => {
  return (
    <>
      <Navbar logo={{ line1: 'TAXI', line2: 'TRANSFER' }} />
      <TripForm />
      <Content>
        <p>
          When arriving at any of London's bustling airports, a reliable and
          convenient taxi transfer service offers an excellent solution for
          travelers seeking a seamless journey to their next destination.{' '}
        </p>

        <p>
          Our airport taxi services are designed to alleviate the stress and
          uncertainty often associated with navigating a new city. With a fleet
          of modern and comfortable vehicles, our skilled and knowledgeable
          drivers are ready to greet passengers with a warm smile and assist
          with their luggage.{' '}
        </p>

        <p>
          Whether arriving at Heathrow, Gatwick, Stansted, Luton, or London City
          Airport, passengers can rest assured that our taxi transfer will be
          punctual and efficient, whisking you away to your desired location in
          the city.
        </p>
      </Content>
      <Fleet />
      <Footer />
    </>
  );
};

export default TaxiTransfer;
