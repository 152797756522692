import React from 'react';
import { useDispatch } from 'react-redux';
import { SideBar, SideBarButton, SideBarSection } from '../styles';
import { logOut } from '../../../features/user/userSlice';
import { useNavigate } from 'react-router-dom';

const AdminNav = ({ activePage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logOut());
    navigate('/login');
  };

  return (
    <SideBar>
      <SideBarSection>
        <SideBarButton
          onClick={() => navigate('/admin-dashboard')}
          isActive={activePage === 'bookings'}
        >
          Bookings
        </SideBarButton>
        <SideBarButton>Users</SideBarButton>
        <SideBarButton
          isActive={activePage === 'drivers'}
          onClick={() => navigate('/admin-dashboard/drivers')}
          isLast
        >
          Drivers
        </SideBarButton>
      </SideBarSection>
      <SideBarSection>
        <SideBarButton>My Profile</SideBarButton>
        <SideBarButton isLast onClick={() => handleLogout()}>
          Logout
        </SideBarButton>
      </SideBarSection>
    </SideBar>
  );
};

export default AdminNav;
