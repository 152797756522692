// src/DriverTable.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const TableContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  text-align: center;
  color: #333;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
`;

const StyledTh = styled.th`
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
`;

const StyledTd = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;

const StyledTr = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const DriverTable = () => {
  const [drivers, setDrivers] = useState([]);

  useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const response = await axios.get('/api/drivers'); // Adjust the URL to your backend endpoint
        setDrivers(response.data);
      } catch (error) {
        console.error('Error fetching drivers:', error);
      }
    };

    fetchDrivers();
  }, []);

  return (
    <TableContainer>
      <Title>Drivers List</Title>
      <StyledTable>
        <thead>
          <tr>
            <StyledTh>Name</StyledTh>
            <StyledTh>Email</StyledTh>
            <StyledTh>Role</StyledTh>
            <StyledTh>Licence ID</StyledTh>
            <StyledTh>Date of Birth</StyledTh>
            <StyledTh>Address</StyledTh>
            <StyledTh>Active</StyledTh>
            <StyledTh>Jobs Started</StyledTh>
            <StyledTh>Jobs Completed</StyledTh>
            <StyledTh>Notes</StyledTh>
          </tr>
        </thead>
        <tbody>
          {drivers.map((driver) => (
            <StyledTr key={driver._id}>
              <StyledTd>{driver.name}</StyledTd>
              <StyledTd>{driver.email}</StyledTd>
              <StyledTd>{driver.role}</StyledTd>
              <StyledTd>{driver.licenceId}</StyledTd>
              <StyledTd>{new Date(driver.dob).toLocaleDateString()}</StyledTd>
              <StyledTd>{driver.address}</StyledTd>
              <StyledTd>{driver.isActive ? 'Yes' : 'No'}</StyledTd>
              <StyledTd>{driver.jobsStarted}</StyledTd>
              <StyledTd>{driver.jobsCompleted}</StyledTd>
              <StyledTd>{driver.notes}</StyledTd>
            </StyledTr>
          ))}
        </tbody>
      </StyledTable>
    </TableContainer>
  );
};

export default DriverTable;
