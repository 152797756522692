import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getSessionBookingAction,
  resetError,
  updateBooking,
} from '../../features/booking/bookingSlice';

import Footer from '../../elements/footer/Footer';
import Navbar from '../../elements/navbar/Navbar';
import Vehicle from './components/Vehicle';
import { MainContainer } from './styles';
import Services from '../home/components/services/Services';
import Processing from '../../components/Processing';
import { getAllVehicles } from '../../features/vehicle/vehicleSlice';
import usePrevious from '../../hooks/usePrevious';
import matchPrice from '../../utils/priceCalculator';

const Vehicles = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    booking,
    isLoading,
    isError,
    message,
    isUpdatingBooking,
    isErrorUpdatingBooking,
  } = useSelector((state) => state.booking);
  const {
    vehicles,
    isLoading: isLoadingVehicle,
    isError: isErrorVechicle,
    message: messageVehicle,
  } = useSelector((state) => state.vehicle);

  const prevBooking = usePrevious(booking);
  const prevIsUpdatingBooking = usePrevious(isUpdatingBooking);
  const prevIsErrorUpdatingBooking = usePrevious(isErrorUpdatingBooking);

  useEffect(() => {
    if (
      prevIsUpdatingBooking &&
      !isUpdatingBooking &&
      !isErrorUpdatingBooking &&
      booking?.price
    ) {
      navigate('/booking');
    }
  }, [
    booking,
    isErrorUpdatingBooking,
    isUpdatingBooking,
    navigate,
    prevIsUpdatingBooking,
  ]);

  const getSessionBooking = useCallback(() => {
    dispatch(getSessionBookingAction());
  }, [dispatch]);

  useEffect(() => {
    getSessionBooking();
  }, [getSessionBooking]);

  useEffect(() => {
    if (!vehicles) {
      dispatch(getAllVehicles({ onlyAvailable: true }));
    }
  }, [vehicles, dispatch]);

  useEffect(() => {
    if (isError && message === 'no session or session expired') {
      navigate('/');
    }

    return () => dispatch(resetError());
  }, [message, navigate, dispatch, isError]);

  const getPrice = (distance, type, hasReturn) => {
    const price = matchPrice(type, distance);

    return hasReturn ? price * 2 * 0.95 : price;
  };

  if (isLoading) {
    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Processing />
      </div>
    );
  }

  return (
    <>
      <Navbar />
      <MainContainer>
        {vehicles?.length > 0 &&
          booking?.miles &&
          vehicles.map((v) => {
            let price = getPrice(
              booking.miles,
              v.type,
              Boolean(booking.returnDateTime)
            ).toFixed(2);

            if (price < 55) price = 55;

            // TODO do this in backend

            return (
              <Vehicle
                id={v.id}
                key={v.id}
                description={v.description}
                price={price}
                name={v.name}
                passengers={v.passengerCapacity}
                luggages={v.luggageCapacity}
                img={`/upload/${v.image}`}
                onSelect={() =>
                  dispatch(
                    updateBooking({
                      vehicleId: v.id,
                      price,
                      vehicleType: v.type,
                    })
                  )
                }
              />
            );
          })}

        <Services />
      </MainContainer>
      <Footer />
    </>
  );
};

export default Vehicles;
