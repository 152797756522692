import React from 'react';

import { PrivacyPolicyContainer } from './styles';

const PrivacyPolicy = () => {
  return (
    <PrivacyPolicyContainer>
      <h2>
        <strong>Privacy</strong> <span>Policy</span>
      </h2>
      <ol>
        <li>
          <h3>Introduction</h3>
          <ol>
            <li>
              The term Rado Chauffeurs or 'us' or 'we' refers to the owner of
              the website whose registered office is 42 High Street, Watlington,
              England, OX49 5PY.
            </li>

            <li>
              We collect and store the minimum information possible to provide
              the services you've requested (i.e. to process your order). We may
              also use this information for auditing, research and analysis to
              operate and improve our technologies and services.
            </li>

            <li>The term 'you' refers to the user or viewer of our website.</li>

            <li>
              Where required to fulfil your transfer, we may pass selected
              information to third parties.
            </li>
            <li>
              We may update this policy from time to time by publishing a new
              version on our website.
            </li>

            <li>
              You should check this page occasionally to ensure you are happy
              with any changes to this policy.
            </li>
          </ol>
        </li>

        <li>
          <h3>How we collect and use your personal data</h3>
          <ol>
            <li>
              In this Section we set out:
              <ol>
                <li>the categories of personal data that we may process;</li>
                <li>the reason why we may process personal data;</li>
                <li>the legal basis for processing this data;</li>
                <li>when we provide your personal data to others;</li>
                <li>the steps we take to protect your personal data. </li>
              </ol>
            </li>

            <li>
              We may collect and process data about your use of our website
              ('analytics data'). The analytics data may include any of the
              following: your IP address; approximate location, internet browser
              type and version, your PC's operating system, the source of
              referral, length of your visit, and website navigation paths, as
              well as information about the frequency, and pattern of your
              visits. We gather this data through the Google Analytics service.
              The legal basis for the processing of this data is our legitimate
              interests in improving our website and service.
            </li>

            <li>
              We collect and process data relating to Customer Profiles
              ('Customer Data'). This data includes: your full name, email
              address, phone number &amp; billing address). You will be required
              to provide this data during the process of booking our service.
              The legal basis for processing this data is for the performance of
              the contract, in particular for us to be able to reliably
              communicate with you regarding your bookings as well as organise
              and provide the service you have requested. Some of this data may
              be passed to a third-party service as part of Transaction
              Information, for more information see section 2.5.
            </li>

            <li>
              We may collect and process personal data relating to Journeys or
              Transfers ('Journey Data'). This data includes: head passenger
              name, passenger mobile telephone number, the route of your
              journey, flight / service numbers &amp; the date and time of your
              travel. The legal basis for processing this data is for the
              performance of the contract. Some of the journey data, where
              required to fulfil your transfer, may be passed to third-parties,
              for example independent taxi drivers. Where possible, without
              impacting the quality of service, this sharing of data is
              restricted to un-identifiable information, for example when
              checking the availability of a third-party only the date, time,
              waypoint postcodes, number of passengers and type of vehicle are
              provided.
            </li>

            <li>
              We may collect and process data relating to the payment of
              services through our website or customer service agents
              ('Transaction Data'). Transaction data includes: card number, card
              expiry date &amp; card security code, as well as some customer
              data: billing address and card holder's name, we also process some
              additional data to run background checks in order to reduce
              fraudulent activity, this data may include: your email address
              &amp; telephone numbers. Transaction data is handled but not
              stored by us and is passed to a third-party service, SagePay. The
              transaction data may be processed for supplying the purchased
              services and keeping proper records of those transactions. The
              legal basis for this processing is the performance of a contract
              between you and us.
            </li>

            <li>
              We may process information contained in or relating to any
              communication that you send to or have with us ('correspondence
              data'). The correspondence data may include: email content, online
              chat transcripts, call recordings and metadata associated with the
              communication. The correspondence data may be processed for the
              purposes of communicating with you. Where possible we avoid
              collecting transaction data in correspondence data, this is done
              via warning notices on emails and pausing call recordings during
              the payment. In the unlikely event transaction data is captured in
              correspondence data records are deleted and purged from our
              system. The legal basis for this processing is in the performance
              of a contract, namely to organize and supply the purchased
              services.
            </li>

            <li>
              We may process your personal data where necessary, for use in the
              defence of legal claims in court or another legal proceeding. The
              legal basis for this is to protect and assert our legal rights,
              the rights of our customers or the rights of others.
            </li>

            <li>
              We may process any of your personal data where such processing is
              necessary to comply with a legal obligation to which we are
              subject, or to protect your interests or the interests of another
              person.
            </li>
          </ol>
        </li>

        <li>
          <h3>Retaining and deleting personal data</h3>
          <ol>
            <li>
              This Section sets out our data retention policies, these are
              designed to ensure that we comply with our legal obligations in
              relation to the retention and deletion of personal data and are
              designed so that we maintain the lowest possible level of risk to
              our customers.
            </li>

            <li>
              Personal data that we process for any purpose or purposes shall
              not be kept for longer than is necessary to fulfil the purchased
              service and our, or a third-parties, legal obligations.
            </li>

            <li>
              We will retain and delete your personal data as follows:
              <ol>
                <li>
                  <i>Analytics Data:</i>

                  <ol>
                    <li>
                      <i>Google Analytics</i> retains data relating to a visit
                      to our website <em>indefinitely</em>, the data collected
                      is non-identifiable.
                    </li>

                    <li>
                      We collect some identifiable analytics data, this is
                      stored for at most <em>1 month</em> following a visit to
                      our website, at the end of which period it will be deleted
                      from our systems.
                    </li>
                  </ol>
                </li>

                <li>
                  <i>Customer Data</i> will be retained for 6 years from the end
                  of last tax period following the completion of all booked
                  journeys, at the end of which period it will be deleted from
                  our systems. The data retention period will restart should any
                  additional services be purchased during the data retention
                  period, it is retained for this period to ensure compliance
                  with the Finance Act of 1988, Schedule 18, Part 3, Section
                  21(2) which requires us to preserve relevant transactional
                  information.
                </li>

                <li>
                  <i>Journey Data</i> will be retained for at most{' '}
                  <em>1 year</em> following the completion of the journey in
                  accordance with our legal obligations as a registered Private
                  Hire Operator, at the end of which period it will be deleted
                  from our systems.
                </li>

                <li>
                  Some Transaction Data will be retained for <em>6 years</em>{' '}
                  from the end of the last company financial year they relate to
                  in accordance to our legal obligations as a limited company,
                  at the end of which period it will be deleted from our
                  systems.
                </li>

                <li>
                  <i>Correspondence Data</i> will be retained for at most{' '}
                  <em>18 months</em> following <i>its collection,</i> at the end
                  of which period it will be deleted from our systems.
                  Transactional correspondences issued by us are retained for 6
                  years from the end of last tax period in order to comply with
                  the Finance Act of 1988, Schedule 18, Part 3, Section 21(2)
                  which requires us to preserve relevant transactional
                  information.
                </li>
              </ol>
            </li>
            <li>
              We may retain your personal data where it is vital for use in
              defence of legal claims in court or another legal proceeding.
            </li>

            <li>
              We may retain your personal data where such retention is necessary
              for compliance with a legal obligation to which we are subject, or
              in order to protect your vital interests or the vital interests of
              another natural person.
            </li>
          </ol>
        </li>

        <li>
          <h3>Your rights</h3>
          <ol>
            <li>
              You have the right to access; rectify or request the removal of
              any personal information we hold about you. We are legally bound
              to provide/remove this information free of charge within 30 days.
              This right however is subject to some limitations:
              <ol>
                <li>There may be a legal reason we cannot remove the data.</li>

                <li>
                  The data may be integral to providing the service, deleting or
                  removing it would result in the cancellation of service. In
                  this case you would be subject to our normal cancellation fees
                  / procedure.
                </li>

                <li>
                  You will be required to supply proof of your identity, in most
                  circumstances we will accept two forms of ID such as a
                  passport, driving license or a copy of a utility bill showing
                  your address listed. This information must be provided by
                  email or post along with your request to which you will
                  receive confirmation of receipt by us within 5 days.
                </li>

                <li>
                  If the request is complicated the timeframe for rectification
                  can be extended by up to two months.
                </li>
              </ol>
            </li>

            <li>
              You have the right to lodge a complaint with a supervisory
              authority.
            </li>

            <li>
              You have the right to withdraw consent to our use of this data. In
              some cases this may result cancellation of your service and any
              relevant charges.
            </li>
          </ol>
        </li>
      </ol>
    </PrivacyPolicyContainer>
  );
};

export default PrivacyPolicy;
