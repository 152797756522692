import axios from 'axios';

const API_URL = '/api/payment';

const newPayment = async (paymentDetails) => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   };

  const response = await axios.post(API_URL + '/newPayment', paymentDetails);

  return response.data;
};

const cancelPayment = async (paymentIntent) => {
  const response = await axios.post(API_URL + '/cancelPayment', {
    paymentIntent,
  });

  return response.data;
};

const paymentService = {
  newPayment,
  cancelPayment,
};

export default paymentService;
