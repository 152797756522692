import styled from 'styled-components';

const MainContainer = styled.section`
  background-color: #d9d9d9;

  padding: 32px 8px;
  @media (min-width: 768px) {
    padding: 100px 0;
  }
`;

const InnerContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 32px;
  display: grid;
  grid-row-gap: 32px;
  margin: auto;
  max-width: 1220px;

  @media (min-width: 768px) {
    padding: 32px 96px 96px;
  }

  p {
    line-height: 130%;
  }

  h1 {
    font-family: 'Saira Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 57px;
    /* identical to box height */

    text-transform: uppercase;

    color: #374151;
  }

  h2 {
    font-family: 'Saira Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 38px;
    text-transform: uppercase;
    color: #bc902d;
  }

  h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 8px;
  }
`;

export { MainContainer, InnerContainer };
