import axios from 'axios';

// axios.defaults.withCredentials = true;

const API_URL = '/api/vehicle';

// Get all vehicles
const getAllVehicles = async ({ onlyAvailable }) => {
  // console.log({ onlyAvailable });
  const config = {
    headers: {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    },
  };

  const response = await axios.get(
    API_URL + `/all/?onlyAvailable=${onlyAvailable}`
  );

  return response.data;
};

const getVehicleById = async (id) => {
  const config = {
    headers: {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    },
  };

  const response = await axios.get(API_URL + `/single`, {
    params: {
      vehicleId: id,
    },
  });

  return response.data;
};

const vehicleService = {
  getAllVehicles,
  getVehicleById,
};

export default vehicleService;
