import styled from 'styled-components';

const NavbarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: #020101d6;
  max-width: 1120px;
  margin: auto;
  color: white;
  font-family: 'Saira Condensed';
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
`;

const NavbarLogo = styled.img`
  max-width: 218px;
  aspect-ratio: 218/51;
  object-fit: contain;
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;

const LogoSmall = styled.img`
  width: 51px;
  height: 51px;
  @media (min-width: 768px) {
    display: none;
  }
`;

const NavbarMenu = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const NavbarMenuItem = styled.li`
  display: ${({ isMobileVisible }) => (isMobileVisible ? 'block' : 'none')};
  margin-left: 20px;
  cursor: pointer;
  font-size: 24px;
  line-height: 90%;
  a {
    text-decoration: none;
    color: inherit;
  }
  color: ${({ selected }) => selected && '#BC902D'};

  &:hover {
    color: #888;
  }

  @media (min-width: 768px) {
    display: block;
  }
`;

const ContactBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #374151;
  font-family: 'Saira Condensed';
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 90%;
  padding: 0 16px;
  max-width: 1120px;

  .socials {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    gap: 16px;
    font-size: 20px;
  }
  .whatsapp {
    color: #128c7e;
    font-size: 22px;
  }

  @media (min-width: 768px) {
    padding: 0 16px;

    .socials {
      gap: 32px;
    }
  }
`;

export {
  NavbarContainer,
  NavbarLogo,
  NavbarMenu,
  NavbarMenuItem,
  ContactBar,
  LogoSmall,
};
