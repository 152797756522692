// src/AddDriver.js
import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  text-align: center;
  color: #333;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

const StyledField = styled(Field)`
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
`;

const Button = styled.button`
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #0056b3;
  }
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const AddDriver = () => {
  const initialValues = {
    name: '',
    email: '',
    password: '',
    role: '',
    licenceId: '',
    dob: '',
    address: '',
    isActive: false,
    jobsStarted: 0,
    jobsCompleted: 0,
    notes: '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    password: Yup.string().required('Password is required'),
    role: Yup.string(),
    licenceId: Yup.string().required('Licence ID is required'),
    dob: Yup.date().nullable(),
    address: Yup.string().required('Address is required'),
    isActive: Yup.boolean(),
    jobsStarted: Yup.number().min(0),
    jobsCompleted: Yup.number().min(0),
    notes: Yup.string(),
  });

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await axios.post('/api/drivers', values); // Adjust the URL to your backend endpoint
      resetForm();
    } catch (error) {
      console.error('Error creating driver:', error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container>
      <Title>Create a New Driver</Title>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <StyledForm>
            <Label htmlFor="name">Name</Label>
            <StyledField type="text" id="name" name="name" />
            <StyledErrorMessage name="name" component="div" />

            <Label htmlFor="email">Email</Label>
            <StyledField type="email" id="email" name="email" />
            <StyledErrorMessage name="email" component="div" />

            <Label htmlFor="password">Password</Label>
            <StyledField type="password" id="password" name="password" />
            <StyledErrorMessage name="password" component="div" />

            <Label htmlFor="role">Role</Label>
            <StyledField type="text" id="role" name="role" />
            <StyledErrorMessage name="role" component="div" />

            <Label htmlFor="licenceId">Licence ID</Label>
            <StyledField type="text" id="licenceId" name="licenceId" />
            <StyledErrorMessage name="licenceId" component="div" />

            <Label htmlFor="dob">Date of Birth</Label>
            <StyledField type="date" id="dob" name="dob" />
            <StyledErrorMessage name="dob" component="div" />

            <Label htmlFor="address">Address</Label>
            <StyledField type="text" id="address" name="address" />
            <StyledErrorMessage name="address" component="div" />

            <Label htmlFor="isActive">Is Active</Label>
            <StyledField type="checkbox" id="isActive" name="isActive" />
            <StyledErrorMessage name="isActive" component="div" />

            <Label htmlFor="jobsStarted">Jobs Started</Label>
            <StyledField type="number" id="jobsStarted" name="jobsStarted" />
            <StyledErrorMessage name="jobsStarted" component="div" />

            <Label htmlFor="jobsCompleted">Jobs Completed</Label>
            <StyledField
              type="number"
              id="jobsCompleted"
              name="jobsCompleted"
            />
            <StyledErrorMessage name="jobsCompleted" component="div" />

            <Label htmlFor="notes">Notes</Label>
            <StyledField as="textarea" id="notes" name="notes" />
            <StyledErrorMessage name="notes" component="div" />

            <Button type="submit" disabled={isSubmitting}>
              Submit
            </Button>
          </StyledForm>
        )}
      </Formik>
    </Container>
  );
};

export default AddDriver;
