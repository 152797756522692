import styled from 'styled-components';

const MainContainer = styled.section`
  background-color: #d9d9d9;

  padding: 32px 8px;
  @media (min-width: 768px) {
    padding: 100px 0;
  }
`;

const InnerContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 32px;
  display: grid;
  grid-row-gap: 32px;
  margin: 0 auto 50px;
  max-width: 1220px;

  @media (min-width: 768px) {
    padding: 32px 96px 96px;
    margin-bottom: 100px;
  }

  h1 {
    font-family: 'Saira Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 38px;
    text-transform: uppercase;
    color: #bc902d;
  }
  h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #212529;
  }
`;

const BookingForm = styled.div`
  display: grid;
  grid-row-gap: 16px;
`;

const InputLabel = styled.label`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`;

const Input = styled.input`
  height: 50px;
  background: #f5f5f5;
  border-radius: 5px;
  border: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding: 0px 16px;

  @media (min-width: 768px) {
    height: 50px;
  }
`;

const Button = styled.button`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 205px;
  background: ${({ danger }) => (danger ? '#212529' : '#BC902D')};
  border-radius: 8px;
  font-family: 'Saira Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 90%;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
`;

const ErrorMsg = styled.div`
  color: red;
`;

export {
  MainContainer,
  InnerContainer,
  BookingForm,
  InputLabel,
  Input,
  Button,
  ErrorMsg,
};
