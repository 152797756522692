import styled from 'styled-components';

const TopSection = styled.section`
  aspect-ratio: 1440/480;
  background-image: url('/images/top-section-image.webp');
  background-size: contain;
  background-position: center;
  max-width: 1120px;
  margin: auto;
`;

const FormSection = styled.section`
  background: #f5f5f5;
`;

const Form = styled.form`
  padding: 32px 16px;
  margin: auto;
  max-width: 740px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;

  .suggestion-item,
  .suggestion-item--active {
    line-height: 170%;
  }

  h2 {
    font-family: 'Saira Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 120%;
    text-transform: uppercase;
    color: #374151;
  }

  @media (min-width: 768px) {
    padding: 64px 32px;
  }
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
  p {
    margin-bottom: 16px;
  }
`;

const FormLabel = styled.label`
  margin-bottom: 5px;
  font-size: 16px;
  font-family: 'Saira Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 90%;
  text-transform: uppercase;
  color: #374151;
  margin-bottom: 16px;
`;

const ErrorText = styled.div`
  color: red;
  margin-top: 8px;
`;

const FormInput = styled.input`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  background: #ffffff;
  border: 1px solid #ffffff;
  padding: 10px 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 90%;
  color: #374151;
`;

const FormButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 32px;
  width: 207px;
  height: 52px;
  background: #bc902d;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-family: 'Saira Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 90%;
  text-transform: uppercase;
  color: #ffffff;

  &:hover {
    background-color: #e6cf98;
  }
`;

const ReturnDate = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

const ServicesSection = styled.section`
  padding: 32px 16px;
  h2 {
    max-width: 1120px;
    margin: auto;
    font-family: 'Saira Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 90%;
    text-transform: uppercase;
    color: #374151;
    margin-bottom: 20px;
  }
  @media (min-width: 768px) {
    padding: 64px 32px;
  }
`;

const Services = styled.div`
  max-width: 1120px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }
`;

const Service = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 32px;
  padding: 16px;
  color: #374151;
  font-family: 'Inter';
  font-style: normal;

  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 110%;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
  }
`;

const ServiceId = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 60px;
  height: 60px;
  background: #bc902d;
  border-radius: 40px;
  font-family: 'Saira Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 90%;
  text-transform: uppercase;
  color: #ffffff;

  @media (min-width: 768px) {
    padding: 10px;
    width: 80px;
    height: 80px;
  }
`;

const WhyChooseUsSection = styled.section`
  padding: 32px 16px;
  background-color: #f5f5f5;
  h2 {
    max-width: 1120px;
    margin: auto;
    font-family: 'Saira Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 90%;
    text-transform: uppercase;
    color: #374151;
    margin-bottom: 20px;
  }

  @media (min-width: 768px) {
    padding: 64px 32px;
  }
`;

const WhyChooseUs = styled.div`
  max-width: 1120px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 32px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 60px;
  }
`;

const WhyAnswer = styled.div`
  color: #374151;
  font-family: 'Inter';
  font-style: normal;

  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 110%;
    margin-bottom: 16px;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
  }
`;

const FleetSection = styled.section`
  padding: 32px 16px;

  h2 {
    max-width: 1120px;
    margin: auto;
    font-family: 'Saira Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 90%;
    text-transform: uppercase;
    color: #374151;
    margin-bottom: 20px;
  }

  @media (min-width: 768px) {
    padding: 64px 32px;
  }
`;

const Fleet = styled.div`
  max-width: 1120px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 64px;
  }
`;

const FleetCar = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;

  img {
    width: 80%;
    margin: auto;

    @media (min-width: 768px) {
      width: 100%;
    }
  }

  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 110%;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
  }

  li {
    border-bottom: 1px solid #dedede;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    & > :nth-child(2) {
      font-weight: bold;
    }
  }
`;

export {
  TopSection,
  ErrorText,
  FormSection,
  Form,
  FormField,
  FormInput,
  FormLabel,
  FormButton,
  ReturnDate,
  ServicesSection,
  Services,
  Service,
  ServiceId,
  WhyChooseUsSection,
  WhyChooseUs,
  WhyAnswer,
  FleetSection,
  Fleet,
  FleetCar,
};
