import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Footer from '../../elements/footer/Footer';
import Navbar from '../../elements/navbar/Navbar';
import Processing from '../../components/Processing';
import { InnerContainer, MainContainer } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  confirmPaymentAction,
  getBookingByClientSecretAction,
  resetError,
} from '../../features/booking/bookingSlice';

const PaymentSuccess = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    booking,
    confirmingPayment,
    isErrorConfirmingPayment,
    gettingBookingByClientSecret,
    isErrorGettingBookingByClientSecret,
  } = useSelector((state) => state.booking);

  const payment_intent = searchParams.get('payment_intent');

  const payment_intent_client_secret = searchParams.get(
    'payment_intent_client_secret'
  );
  const redirect_status = searchParams.get('redirect_status');

  useEffect(() => {
    if (!redirect_status) {
      navigate('/');
    }

    return () => dispatch(resetError());
  }, [dispatch, navigate, redirect_status]);

  useEffect(() => {
    if (!booking && payment_intent_client_secret) {
      dispatch(getBookingByClientSecretAction(payment_intent_client_secret));
    }
  }, [booking, dispatch, payment_intent_client_secret]);

  useEffect(() => {
    if (
      redirect_status === 'succeeded' &&
      payment_intent_client_secret === booking?.clientSecret &&
      booking?.state !== 'PAID'
    ) {
      dispatch(confirmPaymentAction(booking?.clientSecret));
    }
  }, [
    booking?.clientSecret,
    booking?.state,
    payment_intent_client_secret,
    redirect_status,
    dispatch,
  ]);

  if (gettingBookingByClientSecret || confirmingPayment) {
    return <Processing />;
  }

  if (
    (isErrorConfirmingPayment || isErrorGettingBookingByClientSecret) &&
    redirect_status === 'succeeded'
  ) {
    return (
      <>
        <Navbar />
        <MainContainer>
          <InnerContainer>
            <h2>Thank you for your booking!</h2>
            <p>
              Your payment was successful but we have an issue confirming your
              booking, please contact as and mention the details of your booking
            </p>

            {payment_intent && (
              <p>{`To speed up the process and helping us finding your details, please mention this id: ${payment_intent}`}</p>
            )}

            <h2>Help/Support</h2>
            <p>
              Please contact as at booking@rado-chauffers.com or by
              phone/whatsapp at +44 7828 522292, quoting your email and any
              booking details that you remember. Thank you and sorry for any
              incovenience!
            </p>
            <h3>Please print or save this page for your future reference!</h3>
          </InnerContainer>
        </MainContainer>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Navbar />
      <MainContainer>
        <InnerContainer>
          <h2>Thank you for your booking!</h2>
          <p>
            Your payment was successful and you will shortly receive an email
            confirming your booking.
          </p>
          <h2>Booking details</h2>
          <div>
            <h3>Booking reference</h3>
            <p>{booking?._id}</p>
          </div>
          <div>
            <h3>Selected vehicle</h3>
            <p>{booking?.vehicleId}</p>
          </div>

          <div>
            <h3>From</h3>
            <p>{booking?.fromAddress}</p>
          </div>
          <div>
            <h3>To</h3>
            <p>{booking?.toAddress}</p>
          </div>
          <div>
            <h3>Date and time</h3>
            <p>{booking?.dateTime}</p>
          </div>
          <div>
            <h3>Return included</h3>
            <p>{booking?.returnDateTime ? 'YES' : 'NO'}</p>
          </div>
          {booking?.returnDateTime && (
            <>
              <div>
                <h3>Return date & time</h3>
                <p>{booking.returnDateTime}</p>
              </div>
            </>
          )}
          <h2>Contact details</h2>
          <div>
            <h3>Main passenger email</h3>
            <p>{booking?.passengerEmail}</p>
          </div>
          <div>
            <h3>Main passenger phone</h3>
            <p>{booking?.phoneNumber}</p>
          </div>
          <h2>Support</h2>
          <p>
            If any of the booking details are incorect or should you require
            assistance with anything else, please contact as at
            booking@rado-chauffers.com or by phone/whatsapp at +44 7828 522292,
            quoting your booking reference
          </p>
          <h3>Please print or save this page for your future reference!</h3>
        </InnerContainer>
      </MainContainer>
      <Footer />
    </>
  );
};

export default PaymentSuccess;
