import axios from 'axios';

// axios.defaults.withCredentials = true;

const API_URL = '/api/booking';

// Create new booking
const createBooking = async (bookingData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // console.log({ bookingData });

  const response = await axios.post(API_URL + '/add', bookingData, config);

  return response.data;
};

const getSessionBooking = async () => {
  const config = {
    withCredentials: true,
  };

  const response = await axios.get(API_URL + '/session', config);
  return response.data;
};

const getBookingByClientSecret = async (clientSecret) => {
  const response = await axios.post(API_URL + '/booking-by-client-secret', {
    clientSecret,
  });
  return response.data;
};

// Get all bookings
const getAllBookings = async ({ currentPage, itemsPerPage }) => {
  const token = localStorage.getItem('user');

  // console.log({ token });
  const config = {
    headers: {
      'x-auth-token': token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  const response = await axios.get(
    `${API_URL}/all/?page=${currentPage}&limit=${itemsPerPage}`,
    config
  );

  return response.data;
};

// Get all user bookings

const getAllUserBookings = async () => {
  const token = localStorage.getItem('user');

  // console.log({ token });
  const config = {
    headers: {
      'x-auth-token': token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  const response = await axios.get(
    `${API_URL}/all-bookings-of-single-user`,
    config
  );

  return response.data;
};

// Delete user booking
const deleteBooking = async (bookingId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(API_URL + bookingId, config);

  return response.data;
};

const updateBooking = async (updatedBookingData) => {
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`
  //   }
  // };

  const response = await axios.patch(API_URL, updatedBookingData);

  return response.data;
};

const confirmPayment = async (clientSecret) => {
  const response = await axios.patch(API_URL + '/confirm-payment', {
    clientSecret,
  });

  return response.data;
};

const bookingService = {
  createBooking,
  getSessionBooking,
  getBookingByClientSecret,
  getAllBookings,
  getAllUserBookings,
  deleteBooking,
  updateBooking,
  confirmPayment,
};

export default bookingService;
