import styled from 'styled-components';

export const PrivacyPolicyContainer = styled.div`
  padding: 16px 32px;

  h2,
  h3,
  p,
  ol,
  li {
    color: #333;
  }

  li {
    list-style-type: none;
  }

  h2 {
    font-family: 'Saira Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 38px;
    text-transform: uppercase;
    color: #bc902d;
  }

  h3 {
    margin-top: 16px;
  }

  p {
    line-height: 120%;
  }

  ol {
    margin-bottom: 16px;
  }

  ol li {
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: 10px;
    list-style-type: none;
    margin-left: 32px;
  }

  h3 {
    font-weight: bold;
    list-style-type: none;
    margin-bottom: 8px;
  }
`;
