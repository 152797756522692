import React from 'react';
import Navbar from '../../elements/navbar/Navbar';
import TripForm from '../home/components/trip-form/TripForm';
import Fleet from '../home/components/fleet/Fleet';
import Footer from '../../elements/footer/Footer';

const Airport = () => {
  return (
    <>
      <Navbar />
      <TripForm />
      <Fleet />
      <Footer />
    </>
  );
};

export default Airport;
