import React from 'react';

import briefcaseIcon from '../images/briefcase-icon.svg';
import peopleIcon from '../images/people-icon.svg';
import cancelIcon from '../images/cancel-icon.svg';
import nameplateIcon from '../images/nameplate-icon.svg';
import clockIcon from '../images/clock-icon.svg';
import {
  Button,
  PriceContainer,
  VehicleContainer,
  VehicleDetails,
  VehicleOverview,
} from '../styles';

const Vehicle = ({
  id,
  name,
  description,
  img,
  price,
  passengers,
  luggages,
  onSelect,
}) => {
  return (
    <div>
      <VehicleContainer>
        <VehicleOverview>
          <img src={img} alt="car" />
          <p>{description}</p>
        </VehicleOverview>
        <VehicleDetails>
          <h3>{name}</h3>
          <div className="details-header">
            <img className="detail-icon" src={peopleIcon} alt="people" />
            {passengers}
            <img className="detail-icon" src={briefcaseIcon} alt="briefcase" />
            {luggages}
          </div>
          <p>
            <img className="benefit-icon" src={cancelIcon} alt="cancel" />
            Free cancelation
          </p>
          <p>
            <img className="benefit-icon" src={clockIcon} alt="clock" />1 hour
            of free waiting included
          </p>
          <p>
            <img className="benefit-icon" src={nameplateIcon} alt="nameplate" />
            Metting with a nameplate
          </p>
        </VehicleDetails>
        <PriceContainer>
          <h2>£{price}</h2>
          <Button onClick={() => onSelect()}>
            <span>select</span>
          </Button>
        </PriceContainer>
      </VehicleContainer>
    </div>
  );
};

export default Vehicle;
