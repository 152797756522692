import Navbar from '../../elements/navbar/Navbar';
import Footer from '../../elements/footer/Footer';
import TripForm from './components/trip-form/TripForm';
import Services from './components/services/Services';
import WhyUs from './components/why-us/WhyUs';
import Fleet from './components/fleet/Fleet';
import { TopSection } from './styles/Home';

const HomePage = () => {
  return (
    <>
      <Navbar />
      <TopSection />
      <TripForm />
      <Services />
      <WhyUs />
      <Fleet />
      <Footer />
    </>
  );
};

export default HomePage;
