import { FooterInner, FooterOuter, SocialIcon, SocialIcons } from './styles';

import linkedin from './images/linkedin.svg';
import facebook from './images/facebook.svg';
import whatsapp from './images/whatsapp.svg';
import { openInNewTab } from '../../utils/utils';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <FooterOuter>
        <FooterInner>
          <div className="widget">
            <div>
              <h2>ready for your</h2>
              <h2 className="emphased">next</h2>
              <h2>destination</h2>
            </div>
            <div>
              <p>+44 7828 522292</p>
              <p>contact@rado-chauffeurs.com</p>
              <p>Rado Chauffeurs Ltd &copy; {new Date().getFullYear()}</p>
            </div>
          </div>
          <div className="widget">
            <SocialIcons>
              <SocialIcon
                src={whatsapp}
                alt="whatsapp"
                onClick={() => openInNewTab('https://wa.me/00447828522292')}
              />
              <SocialIcon
                src={linkedin}
                alt="linkedin"
                onClick={() =>
                  openInNewTab(
                    'https://www.linkedin.com/company/taxi-transfer/'
                  )
                }
              />
              <SocialIcon
                src={facebook}
                alt="facebook"
                onClick={() =>
                  openInNewTab(
                    'https://www.facebook.com/p/Rado-Chauffeurs-100064145015469/'
                  )
                }
              />
            </SocialIcons>
            <div className="terms-container">
              {/* <p>Privacy policy</p>{' '} */}
              <p>
                <Link to={'/help'}>FAQ</Link>
              </p>
              <p>
                <Link to={'/privacy-policy'}>Privacy Policy</Link>
              </p>
              <p>
                <Link to={'/terms'}>Terms & Conditions</Link>
              </p>
              <p>
                <Link to={'/gdpr'}>GDPR</Link>
              </p>
            </div>
          </div>
        </FooterInner>
      </FooterOuter>
    </>
  );
};

export default Footer;
