import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const UserBookings = ({ bookings }) => {
  const navigate = useNavigate();
  const handleRowClick = (bookingId) => {
    navigate(`/user-dashboard/booking/${bookingId}`);
  };

  return (
    <Container>
      <Heading>User Bookings</Heading>
      <BookingsTable>
        <thead>
          <TableRow>
            <TableHeader>ID</TableHeader>
            <TableHeader>Booking Date</TableHeader>
            <TableHeader>From</TableHeader>
            <TableHeader>To</TableHeader>
            <TableHeader>Miles</TableHeader>
            <TableHeader>Price</TableHeader>
            <TableHeader>Passengers</TableHeader>
            <TableHeader>Is Paid</TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {bookings &&
            bookings?.map((booking) => (
              <TableRow
                key={booking._id}
                onClick={() => handleRowClick(booking._id)}
              >
                <TableCell>{booking._id.substring(0, 6)}</TableCell>
                <TableCell>
                  {new Date(booking.bookingDate).toLocaleDateString()}
                </TableCell>
                <TableCell>{booking.fromAddress}</TableCell>
                <TableCell>{booking.toAddress}</TableCell>
                <TableCell>{booking.miles.toFixed(2)}</TableCell>
                <TableCell>{booking.price} GBP</TableCell>
                <TableCell>{booking.numberOfPassengers}</TableCell>
                <TableCell>{booking.isPaid ? 'Yes' : 'No'}</TableCell>
              </TableRow>
            ))}
        </tbody>
      </BookingsTable>
    </Container>
  );
};

const Container = styled.div`
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
`;

const Heading = styled.h2`
  color: #333;
  margin-bottom: 20px;
  text-align: center;
`;

const BookingsTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  @media (max-width: 768px) {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f4f4f4;
  }

  &:hover {
    background-color: #e6f7ff;
    cursor: pointer;
  }
`;

const TableHeader = styled.th`
  padding: 10px;
  background-color: #f8f9fa;
  border-bottom: 2px solid #ddd;
  text-align: left;

  @media (max-width: 768px) {
    position: sticky;
    top: 0;
    z-index: 1;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

export default UserBookings;
