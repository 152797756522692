import React from "react";
import {
  Service,
  ServiceId,
  ServicesSection,
  Services as StyledServices
} from "../../styles/Home";

const Services = () => {
  return (
    <>
      <ServicesSection>
        <h2>how the service works</h2>
        <StyledServices>
          <Service>
            <ServiceId>1</ServiceId>
            <h3>You choose the route and select a preferred car</h3>
            <p>
              You can order any car of any capacity, from Business Class to a
              large executive MPV
            </p>
          </Service>
          <Service>
            <ServiceId>2</ServiceId>
            <h3>Fill the booking form a choose a payment method</h3>
            <p>Specify the pickup date and time and choose a payment method.</p>
          </Service>
          <Service>
            <ServiceId>3</ServiceId>
            <h3>Our driver meets you with a nameplate at the airport</h3>
            <p>
              When you arrive, the driver will be waiting for you with a
              nameplate at the airport.
            </p>
          </Service>
        </StyledServices>
      </ServicesSection>
    </>
  );
};

export default Services;
