import styled from 'styled-components';

export const Content = styled.div`
  padding: 32px 16px;
  margin: auto;
  max-width: 1120px;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 16px;
  }
`;
