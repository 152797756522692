import { useNavigate } from 'react-router-dom';
import Sidebar from '../../components/sidebard/Sidebar';
import DashboardAdminHOC from '../DashboardAdminHOC';
import { Container } from '../styles';
import AddDriver from './AddDriver';
import DriverTable from './DriverTable';

const DriversManagement = ({ addDriver, allDrivers }) => {
  const navigate = useNavigate();
  return (
    <DashboardAdminHOC activePage={'drivers'}>
      <Container>
        <Sidebar>
          <div onClick={() => navigate('/admin-dashboard/drivers')}>
            Drivers
          </div>
          <div onClick={() => navigate('add')}>Add Driver</div>
          <div>Reports</div>
        </Sidebar>
        <div>
          {addDriver && <AddDriver />}
          {allDrivers && <DriverTable />}
        </div>
      </Container>
    </DashboardAdminHOC>
  );
};

export default DriversManagement;
