import React from 'react';
import {
  FleetCar,
  FleetSection,
  Fleet as StyledFleet,
} from '../../styles/Home';

import fleetBusiness from './images/fleet-business.webp';
import fleetFirstClass from './images/fleet-first-class.webp';
import fleetExecutive from './images/fleet-executive.webp';

const Fleet = () => {
  return (
    <>
      <FleetSection id="fleet">
        <h2>our luxury fleet</h2>
        <StyledFleet>
          <FleetCar>
            <img src={fleetBusiness} alt="fleet-business" />
            <h3>Business</h3>
            <p>
              For a couple or a family. Max 4 passengers, 2 large pieces of
              luggage and 2 small or 4 small.
            </p>
            <ul>
              <li>
                <span>Hourly rate (min 3 hours)</span>
                <span>£55</span>
              </li>
              <li>
                <span>Daily rate (min 8 hours)</span>
                <span>£440</span>
              </li>
              <li>
                <span>Heathrow to Central London</span>
                <span>£135</span>
              </li>
            </ul>
            <p>Prices subject to VAT</p>
          </FleetCar>
          <FleetCar>
            <img src={fleetFirstClass} alt="fleet-business" />
            <h3>First Class</h3>
            <p>
              Firs Class vehicles will carry up to 7 passengers in chauffeur
              driven luxury.
            </p>
            <ul>
              <li>
                <span>Hourly rate (min 3 hours)</span>
                <span>£65</span>
              </li>
              <li>
                <span>Daily rate (min 8 hours)</span>
                <span>£520</span>
              </li>
              <li>
                <span>Heathrow to Central London</span>
                <span>£150</span>
              </li>
            </ul>
            <p>Prices subject to VAT</p>
          </FleetCar>
          <FleetCar>
            <img src={fleetExecutive} alt="fleet-business" />
            <h3>Executive</h3>
            <p>
              The executive is in a class of it’s own. It sets the standard in
              executive-class chauffeur-driven luxury and prestige.
            </p>
            <ul>
              <li>
                <span>Hourly rate (min 3 hours)</span>
                <span>£65</span>
              </li>
              <li>
                <span>Daily rate (min 8 hours)</span>
                <span>£520</span>
              </li>
              <li>
                <span>Heathrow to Central London</span>
                <span>£150</span>
              </li>
            </ul>
            <p>Prices subject to VAT</p>
          </FleetCar>
        </StyledFleet>
      </FleetSection>
    </>
  );
};

export default Fleet;
