import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import bookingService from './bookingService';

const initialState = {
  bookings: null,
  gettingAllBookings: false,
  isErrorGettingAllBookings: false,
  flightDetails: {
    pickupFlightNumber: '',
    returnFlightNumber: '',
    pickupPlateName: '',
    dateArrival: new Date().toISOString(),
    timeArrival: `${new Date().getHours()}:${new Date().getMinutes()}`,
  },
  mainPassenger: {
    passengerName: '',
    passengerEmail: '',
    confirmPassengerEmail: '',
    passengerNumber: '',
  },
  totalAdditionalServices: 0,
  passengerCount: 1,
  bookingConfirmation: '',
  isUpdatingBooking: false,
  isErrorUpdatingBooking: false,
  sessionBooking: null,
  booking: null,
  readyForVehicleSelection: false,
  gettingAllUserBookings: false,
  isErrorGettingAllUserBookings: '',
  userBookings: null,
  gettingBookingByClientSecret: false,
  isErrorGettingBookingByClientSecret: '',
  confirmingPayment: false,
  isErrorConfirmingPayment: '',
};

// Create new booking
export const newBooking = createAsyncThunk(
  'booking/newBooking',
  async (bookingData, thunkAPI) => {
    try {
      return await bookingService.createBooking(bookingData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getSessionBookingAction = createAsyncThunk(
  'booking/getSessionBookingAction',
  async (_, thunkAPI) => {
    try {
      return await bookingService.getSessionBooking();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getBookingByClientSecretAction = createAsyncThunk(
  'booking/getBookingByClientSecret',
  async (clientSecret, thunkAPI) => {
    try {
      return await bookingService.getBookingByClientSecret(clientSecret);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllBookingsAction = createAsyncThunk(
  'booking/getAllBookingsAction',
  async ({ currentPage, itemsPerPage }, thunkAPI) => {
    try {
      return await bookingService.getAllBookings({ currentPage, itemsPerPage });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllUserBookingsAction = createAsyncThunk(
  'booking/getAllUserBookingsAction',
  async (_, thunkAPI) => {
    try {
      return await bookingService.getAllUserBookings();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateBooking = createAsyncThunk(
  'booking/updateBooking',
  async (update, thunkAPI) => {
    try {
      return await bookingService.updateBooking(update);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const confirmPaymentAction = createAsyncThunk(
  'booking/confirmPaymentAction',
  async (update, thunkAPI) => {
    try {
      return await bookingService.confirmPayment(update);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = null;
      state.message = '';
    },
    resetReadyForVehicleSelection: (state) => {
      state.readyForVehicleSelection = false;
    },
    reset: (state) => initialState,
    resetFlight: (state) => {
      state.flightDetails = initialState.flightDetails;
    },
    setFlight: (state, action) => {
      state.flightDetails = { ...state.flightDetails, ...action.payload };
    },
    setMainPassenger: (state, action) => {
      state.mainPassenger = { ...state.mainPassenger, ...action.payload };
    },
    resetMainPassenger: (state) => {
      state.mainPassenger = initialState.mainPassenger;
    },
    setTotalAdditionalServices: (state, action) => {
      state.distance = action.payload;
    },
    setPassengerCount: (state, action) => {
      state.passengerCount = action.payload;
    },
    resetBookings: (state) => {
      state.bookings = null;
    },
    resetUserBookings: (state) => {
      state.userBookings = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(newBooking.pending, (state) => {
        state.isLoading = true;
        state.readyForVehicleSelection = false;
      })
      .addCase(newBooking.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.readyForVehicleSelection = true;
        state.booking = action.payload.booking;
        state.message = '';
        state.isError = false;
      })
      .addCase(newBooking.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getSessionBookingAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSessionBookingAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.booking = action.payload.booking;
      })
      .addCase(getSessionBookingAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.booking = null;
        state.message = 'no session or session expired';
      })
      .addCase(updateBooking.pending, (state) => {
        state.isUpdatingBooking = true;
        state.isErrorUpdatingBooking = false;
      })
      .addCase(updateBooking.fulfilled, (state, action) => {
        state.isUpdatingBooking = false;
        state.isSuccess = true;
        state.booking = action.payload.booking;
      })
      .addCase(updateBooking.rejected, (state, action) => {
        state.isUpdatingBooking = false;
        state.isErrorUpdatingBooking = true;
      })
      .addCase(confirmPaymentAction.pending, (state) => {
        state.confirmingPayment = true;
        state.isErrorConfirmingPayment = false;
      })
      .addCase(confirmPaymentAction.fulfilled, (state, action) => {
        state.confirmingPayment = false;
        state.booking = action.payload.booking;
      })
      .addCase(confirmPaymentAction.rejected, (state, action) => {
        state.confirmingPayment = false;
        state.isErrorConfirmingPayment = action.payload;
      })
      .addCase(getAllBookingsAction.pending, (state) => {
        state.gettingAllBookings = true;
        state.isErrorGettingAllBookings = false;
      })
      .addCase(getAllBookingsAction.fulfilled, (state, action) => {
        state.gettingAllBookings = false;
        state.bookings = action.payload.bookings;
      })
      .addCase(getAllBookingsAction.rejected, (state, action) => {
        state.gettingAllBookings = false;
        state.isErrorGettingAllBookings = true;
      })
      .addCase(getAllUserBookingsAction.pending, (state) => {
        state.gettingAllUserBookings = true;
        state.isErrorGettingAllUserBookings = false;
      })
      .addCase(getAllUserBookingsAction.fulfilled, (state, action) => {
        state.gettingAllUserBookings = false;
        state.userBookings = action.payload.bookings;
      })
      .addCase(getAllUserBookingsAction.rejected, (state, action) => {
        state.gettingAllUserBookings = false;
        state.isErrorGettingAllUserBookings = action.payload;
      })
      .addCase(getBookingByClientSecretAction.pending, (state) => {
        state.gettingBookingByClientSecret = true;
        state.isErrorGettingBookingByClientSecret = false;
      })
      .addCase(getBookingByClientSecretAction.fulfilled, (state, action) => {
        state.gettingBookingByClientSecret = false;
        state.booking = action.payload.booking;
      })
      .addCase(getBookingByClientSecretAction.rejected, (state, action) => {
        state.gettingBookingByClientSecret = false;
        state.isErrorGettingBookingByClientSecret = action.payload;
      });
  },
});

export const {
  reset,
  resetError,
  resetReadyForVehicleSelection,
  resetFlight,
  setFlight,
  setMainPassenger,
  resetMainPassenger,
  setTotalAdditionalServices,
  setPassengerCount,
  resetBookings,
  resetUserBookings,
} = bookingSlice.actions;
export default bookingSlice.reducer;
