import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const AdminRoute = ({ children }) => {
  const [showRoute, setShowRoute] = useState(true);
  const { isAdmin } = useSelector((state) => state.user);

  useEffect(() => {
    function checkIfAdmin() {
      const localIsAdmin = localStorage.getItem('isAdmin');

      if (isAdmin || JSON.parse(localIsAdmin)) {
        setShowRoute(true);
      } else showRoute(false);
    }
    checkIfAdmin();
  }, [isAdmin, showRoute]);

  if (!showRoute) {
    // user is not admin
    return <Navigate to="/login" />;
  }
  return children;
};

export default AdminRoute;
