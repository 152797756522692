import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PlacesAutocomplete, {
  getLatLng,
  geocodeByAddress,
} from 'react-places-autocomplete';
import { Button } from '../../../booking/styles';

import {
  ErrorText,
  Form,
  FormButton,
  FormField,
  FormInput,
  FormLabel,
  FormSection,
  ReturnDate,
} from '../../styles/Home';
import {
  getSessionBookingAction,
  newBooking,
  resetReadyForVehicleSelection,
} from '../../../../features/booking/bookingSlice';

const TripForm = () => {
  const { booking, isLoading, isError, message, readyForVehicleSelection } =
    useSelector((state) => state.booking);
  // console.log({ message });
  const [hasReturn, setHasReturn] = useState(booking?.returnDateTime);
  const [fromAddress, setFromAddress] = useState('');
  const [toAddress, setToAddress] = useState('');
  const [dateTime, setDateTime] = useState(minDepartureDate());
  const [returnDateTime, setReturnDateTime] = useState('');
  const [miles, setMiles] = useState(0);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getSessionBooking = useCallback(() => {
    dispatch(getSessionBookingAction());
  }, [dispatch]);

  useEffect(() => {
    getSessionBooking();
  }, [getSessionBooking]);

  useEffect(() => {
    if (readyForVehicleSelection) {
      navigate('/vehicles');
    }

    return () => {
      dispatch(resetReadyForVehicleSelection());
    };
  }, [dispatch, navigate, readyForVehicleSelection]);

  useEffect(() => {
    if (booking?._id) {
      setFromAddress(booking.fromAddress);
      setToAddress(booking.toAddress);
      setDateTime(new Date(booking.dateTime).toISOString().substring(0, 16));
      if (booking?.returnDateTime) {
        setReturnDateTime(
          new Date(booking.returnDateTime).toISOString().substring(0, 16)
        );
        setHasReturn(true);
      }
      setMiles(booking.miles);
    }
  }, [booking]);

  const schema = Yup.object().shape({
    fromAddress: Yup.string().required('From address is required'),
    toAddress: Yup.string().required('To address is required'),
    dateTime: Yup.date()
      .transform(function (castValue, originalValue) {
        return Number.isNaN(originalValue)
          ? castValue
          : new Date(originalValue);
      })
      .nullable()
      .typeError('Invalid Date, please select a date and time')
      .required('Mandatory field message'),
    returnDateTime: Yup.date()
      .transform(function (castValue, originalValue) {
        return Number.isNaN(originalValue)
          ? castValue
          : new Date(originalValue);
      })
      .nullable()
      .typeError('Invalid Date, please select a date and time'),
    miles: Yup.number().required(),
  });

  const handleFromChange = (address) => {
    setErrors({ ...errors, fromAddress: '' });
    setFromAddress(address);
  };

  const handleFromSelect = async (address) => {
    const results = await geocodeByAddress(address);
    const latLng = await getLatLng(results[0]);

    setFromAddress(address);
  };

  const handleToChange = (address) => {
    setErrors({ ...errors, toAddress: '' });
    setToAddress(address);
  };

  const handleToSelect = async (address) => {
    const results = await geocodeByAddress(address);
    const latLng = await getLatLng(results[0]);

    setToAddress(address);
  };

  useEffect(() => {
    function calculateDistance(origin, destination) {
      const service = new window.google.maps.DistanceMatrixService();
      service.getDistanceMatrix(
        {
          origins: [fromAddress],
          destinations: [toAddress],
          travelMode: 'DRIVING',
          unitSystem: window.google.maps.UnitSystem.IMPERIAL, // set the unit system to IMPERIAL
        },
        (response, status) => {
          if (status !== 'OK') {
            //    console.log('Error:', status);
          } else {
            const distance =
              response.rows[0].elements[0].distance?.value / 1609.34; // convert meters to miles
            //   console.log('Distance:', distance);
            if (distance) setMiles(distance);
          }
        }
      );
    }

    if (fromAddress && toAddress) {
      calculateDistance();
    }
  }, [fromAddress, toAddress]);

  const handleDateTimeChange = (event) => {
    setErrors({ ...errors, dateTime: '' });
    setDateTime(event.target.value);
  };
  const handleReturnDateTimeChange = (event) => {
    setErrors({ ...errors, returnDateTime: '' });
    setReturnDateTime(event.target.value);
  };

  const handleGetPrices = async (event) => {
    event.preventDefault();
    const bookingModel = {
      fromAddress,
      toAddress,
      hasReturn,
      dateTime:
        new Date(dateTime).getTime() -
        new Date(dateTime).getTimezoneOffset() * 60000,
      returnDateTime: hasReturn
        ? new Date(returnDateTime).getTime() -
          new Date(returnDateTime).getTimezoneOffset() * 60000
        : null,
      miles,
    };

    try {
      await schema.validate(bookingModel, {
        abortEarly: false,
      });

      setErrors({});
      dispatch(newBooking(bookingModel));

      // TODO:
      // navigate on the next page on success (via effect)
    } catch (validationErrors) {
      const newErrors = {};
      validationErrors.inner?.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      setErrors(newErrors);
    }
  };

  function minDepartureDate() {
    // Get the current date and time
    const currentDate = new Date();

    // Add 6 hours to the current time
    currentDate.setHours(currentDate.getHours() + 6);

    // Format the date as a string in "YYYY-MM-DDTHH:mm" format
    const formattedDate = currentDate.toISOString().slice(0, 16);

    return formattedDate;
  }

  return (
    <>
      <FormSection id="book">
        <Form>
          <h2>where should we take you?</h2>
          <FormField>
            <FormLabel>From</FormLabel>
            <div>
              <PlacesAutocomplete
                value={fromAddress}
                searchOptions={{
                  componentRestrictions: {
                    country: 'uk',
                  },
                }}
                onChange={handleFromChange}
                onSelect={handleFromSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <FormInput
                      {...getInputProps({
                        placeholder: 'Enter your address',
                        className: 'location-search-input',
                      })}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion, i) => {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item';
                        const style = suggestion.active
                          ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                          : { backgroundColor: '#ffffff', cursor: 'pointer' };
                        return (
                          <div
                            key={i}
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </div>

            {errors.fromAddress && <ErrorText>{errors.fromAddress}</ErrorText>}
          </FormField>
          <FormField>
            <FormLabel>Going to</FormLabel>
            <div>
              <PlacesAutocomplete
                value={toAddress}
                searchOptions={{
                  componentRestrictions: {
                    country: 'uk',
                  },
                }}
                onChange={handleToChange}
                onSelect={handleToSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <FormInput
                      {...getInputProps({
                        placeholder: 'Enter your address',
                        className: 'location-search-input',
                      })}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion, i) => {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item';
                        const style = suggestion.active
                          ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                          : { backgroundColor: '#ffffff', cursor: 'pointer' };
                        return (
                          <div
                            key={i}
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </div>
            {errors.toAddress && <ErrorText>{errors.toAddress}</ErrorText>}
          </FormField>
          <FormField>
            <FormLabel>Pickup date and time</FormLabel>
            <p>Pickup time must be at least 6 hours in advance!</p>
            <p>
              If you need a pickup time between 10 PM and 6 AM, or if you
              require a booking within the next 6 hours, please call us before
              making a reservation to confirm availability. Thank you!
            </p>
            <FormInput
              type="datetime-local"
              min={minDepartureDate()}
              placeholder="eg. 20/04/2023 18:55"
              value={dateTime}
              onChange={handleDateTimeChange}
            />
            {errors.dateTime && <ErrorText>{errors.dateTime}</ErrorText>}
          </FormField>
          <FormField>
            <FormLabel>
              {hasReturn ? 'Return date and time' : 'Add a return?'}
            </FormLabel>
            {!hasReturn && (
              <FormInput
                onFocus={() => setHasReturn(true)}
                type="text"
                placeholder="Add a return date and get 5% discount"
              />
            )}
            {hasReturn && (
              <ReturnDate>
                <FormInput
                  type="datetime-local"
                  placeholder="eg. 20/04/2023 18:55"
                  min={dateTime}
                  value={returnDateTime}
                  onChange={handleReturnDateTimeChange}
                />
                <Button
                  danger
                  onClick={() => {
                    setReturnDateTime('');
                    setErrors({ ...errors, returnDateTime: '' });
                    setHasReturn(false);
                  }}
                >
                  cancel return
                </Button>
              </ReturnDate>
            )}
            {errors.returnDateTime && hasReturn && (
              <ErrorText>{errors.returnDateTime}</ErrorText>
            )}
          </FormField>
          <FormButton onClick={(ev) => handleGetPrices(ev)}>
            Get Prices
          </FormButton>
        </Form>
      </FormSection>
    </>
  );
};

export default TripForm;
