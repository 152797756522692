import styled from 'styled-components';

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  width: 100%;
`;

export const DashboardHeading = styled.h1`
  margin-bottom: 20px;
`;

export const DashboardMain = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
`;

export const SideBar = styled.div`
  background-color: lightgray;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
`;

export const SideBarSection = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-start;
`;

export const SideBarButton = styled.div`
  border-right: ${({ isLast }) => (isLast ? 'none' : '1px solid red')};
  padding: 0 16px;
  text-decoration: ${({ isActive }) => (isActive ? 'underline' : 'none')};
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Container = styled.div`
  margin: auto;
  display: flex;
  flex-grow: 1;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Table = styled.table`
  table-layout: fixed;
  overflow-x: auto;
  margin: 32px 16px;

  tr {
    cursor: pointer;
    &:hover {
      background-color: lightblue;
    }
  }
`;

export const Th = styled.th`
  background-color: #f2f2f2;
  padding: 10px;
  border: 1px solid #ccc;
`;

export const Td = styled.td`
  padding: 10px;
  border: 1px solid #ccc;
  overflow: hidden;
`;

export const PageButton = styled.button`
  padding: 5px 10px;
  margin: 0 5px;
  font-size: 16px;
  background-color: ${(props) => (props.active ? '#3498db' : 'white')};
  color: ${(props) => (props.active ? 'white' : '#333')};
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.active ? '#3498db' : '#f5f5f5')};
  }
`;

export const Ellipsis = styled.span`
  font-size: 16px;
  margin: 0 5px;
  color: #333;
`;

export const ErrorMessage = styled.p`
  font-size: 16px;
  margin: 16px 0;
  color: red;
`;

export const Message = styled.p`
  font-size: 16px;
  margin: 16px 0;
  color: #333;
`;
