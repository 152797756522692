import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import usePrevious from '../../hooks/usePrevious';
import { useNavigate } from 'react-router-dom';
import { registerAction } from '../../features/user/userSlice';
import Navbar from '../../elements/navbar/Navbar';
import Footer from '../../elements/footer/Footer';
import Processing from '../../components/Processing';

const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 32px;
  gap: 32px;
  height: 100vh;

  h1 {
    font-size: 24px;
    margin-bottom: 8px;
  }
`;

const RegisterForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 63px 32px;
  border: 1px solid #ccc;
  border-radius: 5px;
  gap: 8px;
  width: 320px;

  p {
    line-height: 120%;
    text-align: center;
  }
`;

const FormField = styled(Field)`
  padding: 10px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
`;

const ErrorMessageWrapper = styled(ErrorMessage)`
  color: red;
`;

const Button = styled.button`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 220px;
  background: #bc902d;
  border-radius: 8px;
  cursor: pointer;
  font-family: 'Saira Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 90%;
  text-transform: uppercase;
  color: #ffffff;
  margin: 16px auto;
`;

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Username is required'),
  email: Yup.string().email().required('Email is required'),
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);

  const { isRegistering, errorRegister } = useSelector((state) => state.user);

  const prevIsRegistering = usePrevious(isRegistering);

  useEffect(() => {
    if (prevIsRegistering && !errorRegister) {
      setShowAlert(true);
    }
  }, [errorRegister, navigate, prevIsRegistering]);

  const initialValues = {
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  };

  const handleSubmit = ({ name, email, password }) => {
    dispatch(registerAction({ name, email, password }));
  };

  if (showAlert) {
    return (
      <RegisterContainer>
        <h1>Thank you for joining us!</h1>
        <p>You can now use your email and password to login.</p>
        <Button
          onClick={() => {
            setShowAlert(false);
            navigate('/login');
          }}
          type="button"
        >
          Login
        </Button>
      </RegisterContainer>
    );
  }

  return (
    <>
      <Navbar />
      <RegisterContainer>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ errors, touched }) => (
            <RegisterForm>
              <h1>Join us</h1>
              <FormField type="text" name="name" placeholder="Full name" />
              <ErrorMessageWrapper name="name" component="div" />
              <FormField type="email" name="email" placeholder="Email" />
              <ErrorMessageWrapper name="email" component="div" />
              <FormField
                type="password"
                name="password"
                placeholder="Password"
              />
              <ErrorMessageWrapper name="password" component="div" />
              <FormField
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
              />
              <ErrorMessageWrapper name="confirmPassword" component="div" />
              <Button type="submit">Register</Button>
              {errorRegister && <div>{errorRegister}</div>}

              <p>
                by creating an account you agree to our{' '}
                <a href="/terms" rel="noopener" target="_blank">
                  terms and conditions
                </a>
              </p>
            </RegisterForm>
          )}
        </Formik>
        {isRegistering && <Processing />}
      </RegisterContainer>
      <Footer />
    </>
  );
}

export default Register;
