import React from 'react';
import styled from 'styled-components';
import { PDFDownloadLink } from '@react-pdf/renderer';

import Invoice from './Invoice';

const BookingDetails = ({ booking }) => {
  return (
    <Container>
      <Heading>Booking Details</Heading>
      <div>
        {booking.isPaid && (
          <div>
            <PDFDownloadLink
              style={{
                color: '#333',
                border: '1px solid lightgray',
                borderRadius: 8,
                padding: '8px 16px',
                textDecoration: 'none',
                fontWeight: 'bold',
              }}
              document={<Invoice booking={booking} />}
              fileName={`${booking._id}-invoice.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? 'Loading document...' : 'Download invoice'
              }
            </PDFDownloadLink>
          </div>
        )}
      </div>
      <BookingTable>
        <tbody>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>{booking._id.substring(0, 6)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Booking Date</TableCell>
            <TableCell>
              {new Date(booking.bookingDate).toLocaleString()}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>From</TableCell>
            <TableCell>{booking.fromAddress}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>To</TableCell>
            <TableCell>{booking.toAddress}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Miles</TableCell>
            <TableCell>{booking.miles.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Price</TableCell>
            <TableCell>{booking.price} GBP</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Number of Passengers</TableCell>
            <TableCell>{booking.numberOfPassengers}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Is Paid</TableCell>
            <TableCell>{booking.isPaid ? 'Yes' : 'No'}</TableCell>
          </TableRow>
        </tbody>
      </BookingTable>
    </Container>
  );
};

const Container = styled.div`
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
`;

const Heading = styled.h2`
  color: #333;
  margin-bottom: 20px;
  text-align: center;
`;

const BookingTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f4f4f4;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;

export default BookingDetails;
