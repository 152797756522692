import styled from 'styled-components';

const MainContainer = styled.section`
  background-color: #f5f5f5;
  padding: 50px 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 50px;
`;

const VehicleContainer = styled.div`
  height: auto;
  max-width: 1120px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(1 1fr);

  @media (min-width: 768px) {
    grid-template-columns: ${({ tripPage }) =>
      tripPage ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)'};
  }
`;

const VehicleOverview = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 0px 32px 16px;
  img {
    width: 100%;
  }
  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  }

  @media (min-width: 768px) {
    padding: 32px;
  }
`;

const VehicleDetails = styled.div`
  padding: 0 32px;
  background-color: white;
  display: grid;
  grid-row-gap: 8px;
  @media (min-width: 768px) {
    padding: 32px;
    background-color: ${({ tripPage }) => (tripPage ? '#f5f5f5' : 'white')};
  }

  h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
  }
  .details-header {
    display: flex;
    gap: 8px;
  }

  .detail-icon {
    width: 20px;
    height: 20px;
  }
  .benefit-icon {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 32px 0;

  h2 {
    font-family: 'Saira Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 90%;
    text-transform: uppercase;
    margin-bottom: 32px;
  }

  @media (min-width: 768px) {
    padding: 0;
    height: 100%;
    background-color: #d9d9d9;
  }
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 220px;
  background: #bc902d;
  border-radius: 8px;
  cursor: pointer;

  span {
    font-family: 'Saira Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 90%;
    text-transform: uppercase;
    color: #ffffff;
  }
`;

export {
  MainContainer,
  VehicleContainer,
  VehicleOverview,
  VehicleDetails,
  Button,
  PriceContainer,
};
