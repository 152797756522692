import React from 'react';

const Terms = () => {
  return (
    <div style={{ padding: '16px 32px' }}>
      <p>
        <b>Terms, conditions and fares:</b>
      </p>
      <p>
        <b>1. DEFINITIONS AND INTERPRETATION</b>
      </p>
      <p>
        1.1. “Booking” means a Customer’s request for Services howsoever
        communicated to the Company as evidenced by the Company’s records.
      </p>
      <p>
        1.2. “Business Day” means a day (excluding Saturdays, Sundays and public
        holidays) which is also a day on which clearing banks in the City of
        London are open.
      </p>
      <p>
        1.3. “Cancellation Fee” means a fee charged by the Company for the
        cancellation of the Services by the Customer prior to the booked
        collection time or after the Passenger Vehicle has departed to fulfil
        the Booking (whether or not it has arrived at the Collection Address) as
        detailed in the Price List (such fees will be higher for collection
        outside of London).
      </p>
      <p>
        1.4. “Contract” means a contract for the provision of the Services to
        the Customer and each such Contract shall incorporate these Terms.
      </p>
      <p>
        1.5. “London” means the areas defined as Zone 1 Zone 2 and Zone 3 in the
        map of the London Underground as varied from time to time by Transport
        for London.
      </p>
      <p>
        1.6. “Charges” means the Company’s charges shown in the Price List or
        other published literature.
      </p>
      <p>
        1.7. “Christmas Period” means between 18:00 PM on 24 December to 06:00
        AM on 27 December, in any year.
      </p>
      <p>
        1.8. “New Year’s Period” means between 18:00 PM on 31 December to 06:00
        AM on 2 January, in any year.
      </p>
      <p>
        1.9. “Collection Address” means the address stated by the Customer at
        the time of making the Booking as the address from which the Vehicle
        shall collect the Customer, any Passengers or the Goods.
      </p>
      <p>1.10. “Company” means Rado Chauffeurs Ltd.</p>
      <p>
        1.11. “Credit and Debit Card Services” means Services provided by the
        Company to the Customer, to be paid for by the Customer to the Company
        by way of a credit or debit card payment.
      </p>
      <p>
        1.12. “Customer” means any person(s), firm or company to whom the
        Company provides Services.
      </p>
      <p>
        1.13. “Driver” means any person who provides his/her services, for the
        transportation of Passengers by a Passenger Vehicle, to the Company
        pursuant to a contract for services.
      </p>
      <p>
        1.14. “Goods” means any goods transported by the Company pursuant to a
        Contract.
      </p>
      <p>
        1.15. “In Writing” means any written communication including email and
        SMS.
      </p>
      <p>
        1.16. “Passenger(s)” means the Customer and such persons who the
        Customer shall authorise and/or permit to make use of the Passenger
        Services by traveling in a Passenger Vehicle.
      </p>
      <p>
        1.17. “Passenger Vehicle” means any vehicle provided by the Company for
        the carriage of Passengers.
      </p>
      <p>
        1.18. “Price List” means the list maintained by the Company of its
        Charges relating to the Services from time to time and a current copy of
        which can be obtained on request.
      </p>
      <p>1.19. “Services” means the transportation of Passengers by car.</p>
      <p>
        1.20. “Terms” means these terms and conditions, as amended from time to
        time, to which the Passenger(s) agree upon using any of our services.
      </p>
      <p>
        1.21. “Extra Waiting Time Fee” means a fee charged to the Customer by
        the Company in accordance with clauses 2.3.5, 2.3.6, 2.3.7 or 2.3.8 as
        detailed in the Price List.
      </p>
      <p>
        1.22. “Operating Centre” means place where the booking is taken by the
        Company to provide Services for the Customer.
      </p>
      <p>
        <b>2. SERVICES</b>
      </p>
      <p>
        <b>2.1. Account, Credit and Debit Card Services</b>
      </p>
      <p>
        2.1.1. When making a Booking for the Credit and Debit Card Services, the
        Customer must select to pay the Company for the Services either by way
        of a credit card or a debit card payment, details of which shall be
        processed by the Company. The company is processing/capturing all
        payments at least one day before time of collection or at the time of
        allocation to the driver. If by any reason payment will be not settled
        or impossible to captured – the booking automatically will be cancelled.
      </p>
      <p>
        2.1.2. In consideration for the performance of the Credit and Debit Card
        Services, the Customer shall pay the Charges by way of a credit or debit
        card payment in accordance with clause 2.1.1.
      </p>
      <p>
        2.1.3. When placing the booking for the Account Services, the Customer
        shall pay for all Charges onto company account not later than due date
        on the invoice issued after the specific period agreed between the
        Customer and the Company.
      </p>
      <p>
        <b>2.2. Cash Services</b>
      </p>
      <p>
        2.2.1. In consideration for the performance of the Cash Services, the
        Customer shall pay the Charges to the Driver upon completion of the
        Services (ex. upon arrival at the Customer’s destination) or earlier if
        the Driver shall, at his absolute discretion, decide.
      </p>
      <p>
        2.2.2. In respect of Cash Services, payment shall be made in cash and
        the Driver shall not accept any other form of payment.
      </p>
      <p>
        2.2.3. The company reserves the right to accept Cash Services (payment)
        at its own discretion, as this is not accepted standard payment method.
        Cash Services can only be booked with the assistance of an operator, via
        phone or email.
      </p>
      <p>
        <b>2.3. Passenger Services – Fees and Charges</b>
      </p>
      <p>
        2.3.1. The price provided to the Customer at the time of making the
        Booking shall be based on the journey specified by the Customer at the
        time of Booking (the “Original Journey”).&nbsp;
        <b>
          The price provided to the Customer at the time of making the Booking
          may not include additional fees like meet and greet, congestion
          charge, drop-off or pick-up charges, extra waiting time or car park –
          please see “finance” section in the customer app for full additional
          charges or payment section on website where all extra costs are
          detailed. If the price is provided by email or over the phone all fees
          will be provided at the time of the booking.
        </b>
        &nbsp;The price quoted by the Company shall be based upon the Company’s
        chosen route between the Collection Address and the Customer’s
        destination (via any other pick -up points or drop-off points stated by
        the Customer at the time of making the Booking). Should the Customer
        wish to take a specific route (which is different to that taken by the
        Company) the Company reserves the right to charge the Customer an
        additional charge in relation to that Booking.
      </p>
      <p>
        2.3.2. The Company reserves the right to charge the Customer for any
        additional costs which may be incurred by the Company as a result of any
        variation or deviation from the Original Journey specified at the time
        of Booking, as detailed below.
      </p>
      <p>
        2.3.3. The Company shall be entitled to vary the Price List from time to
        time by giving not less than 1 Business Days notice to the Customer.
      </p>
      <p>
        2.3.4. If a Booking is cancelled by the Customer on or after arrival of
        the Passenger Vehicle at the Collection Address, a Cancellation Fee
        shall be charged. If a Booking is cancelled by a Customer after the
        Passenger Vehicle has departed to full-fill the Booking (but prior to
        its arrival at the Collection Address) the Company shall be entitled, at
        its sole discretion, to charge a Cancellation Fee.
      </p>
      <p>
        2.3.5. The Customer and Passenger(s) and any luggage or personal items
        shall be ready for collection at the time stipulated by the Customer
        when the Booking is made. The Company will allow a maximum of 15 minutes
        free waiting or loading time when picking up Passenger(s) from a regular
        address. In the event that all booked Passenger(s) have not boarded the
        Passenger Vehicle within 15 minutes of the stipulated time, the Company
        reserves the right to charge the Customer a Waiting Time Fee (See Price
        List) proportionate to the delay in departure from the Collection
        Address, which will, for the avoidance of doubt, include the first 15
        minutes. After the free 15 minutes of waiting time pass, if contact
        cannot be made with the Customer, the booking will be automatically
        considered cancelled due to “no show” policy.
      </p>
      <p>
        2.3.6. In relation to collection of the Customer and any Passenger(s)
        from a London airport (Heathrow, Gatwick, Stansted, Luton, London City
        or Southend) or major port/cruise terminal (Southampton, Portsmouth,
        Dover, Harwich, Tilbury), the Company will allow a maximum of 30 minutes
        free waiting time and parking starting from the scheduled pick-up time*
        (for airports pick-ups, scheduled time is worked out by adding the
        chosen time frame (M&amp;G period) after the last known estimated
        arrival time of an inbound international flight).&nbsp;
        <b>
          The Company reserves the right to charge the Customer a Waiting Time
          Fee proportionate to the delay in departure, which shall not include
          the first 30 minutes, but only the period waited after this initial
          time frame.&nbsp;
        </b>
        For the purposes of this clause the “last known estimated arrival time”
        will either be (a) if the Customer provides a flight number at the time
        of making the Booking, the Company will monitor the relevant flight and
        alter the collection time accordingly; or (b) if the Customer does not
        provide a flight number, the time which has been specified by the
        Customer
        <b>
          . After the 60 minutes of waiting time pass, if contact cannot be made
          with the Customer, the booking will be automatically considered
          cancelled due to “no show” policy.
        </b>
      </p>
      <p>
        2.3.7. In relation to collection of the Customer and any Passenger(s)
        from a London train station, the Company will allow a maximum of 15
        minutes free waiting time from the scheduled pick-up time. The “meet
        &amp; greet” service is not offered as standard and is optional (See
        Price List). In the event that all booked Passenger(s) have not boarded
        the Passenger Vehicle within 15 minutes of the stipulated time, the
        Company reserves the right to charge the Customer a Waiting Time Fee
        (See Price List) proportionate to the delay in departure from the
        Collection Address, which will, for the avoidance of doubt, include the
        first 15 minutes. After the free 15 minutes of waiting time pass, if
        contact cannot be made with the Customer, the booking will be
        automatically considered cancelled due to “no show”.
      </p>
      <p>
        2.3.8. All parking fees will be chargeable to the Customer for
        collections from airports, ports, international and domestic train
        terminals or any regular address which requires paid parking. Car
        parking fees are set out in our Price List.
      </p>
      <p>
        2.3.9. In the event that the Customer requires the Company at the
        beginning and/or during the course of the Original Journey to make any
        alternative pick-up(s) or collection(s) of Passenger(s) during the
        course of the Original Journey or to drop off Passengers at any
        locations other than as specified in the Original Journey or to take any
        variation from the Original Journey as specified at the time of Booking,
        additional charges may be applied by the Company, as detailed in the
        Price List. The Company reserves the right to decline carriage to any
        Passenger who do not agree to the price being recalculated to new
        specifications.
      </p>
      <p>
        2.3.10. In the event that the Customer requires more than four (4)
        passengers to travel in a Passenger Vehicle additional charges may be
        levied by the Company (as detailed in the Price List) for the provision
        of a larger Passenger Vehicle or the carriage of additional Passengers
        in excess of four.
      </p>
      <p>
        2.3.11. The Company shall be entitled to exercise a lien over any
        property belonging to the Customer in its possession pending payment of
        any Charges due to the Company.
      </p>
      <p>
        2.3.12. The Company reserves the right to charge the Customer a
        surcharge for all journeys made during the Christmas Period and Public
        Holidays, such surcharge as detailed in the Price List.
      </p>
      <p>
        2.3.13. The Company reserves the right to charge the Customer a fee for
        extra luggage (as detailed in the Price List).
      </p>
      <p>
        <b>
          2.3.14. The Company reserves the right to decline carriage to any
          Passenger(s) whose booking details at the time of pick-up do not match
          those of the Original Journey (ex. Customer books Original Journey for
          2 passengers and 2 pieces of luggage and at pick-up he boards
          Passenger Vehicle with 4 pieces of luggage).
        </b>
      </p>
      <p>
        2.3.15 The company reserves the right to accept or reject any booking
        done online even if it was prepaid. In this case Customer will be
        refunded in full.
      </p>
      <p>
        <b>2.4. Passenger Services – General</b>
      </p>
      <p>
        2.4.1. The Company may, in its absolute discretion, decline to accept
        any Booking.
      </p>
      <p>
        2.4.2. The Company shall use reasonable endeavours to provide a
        Passenger Vehicle in good working order of the type specified by the
        Customer (or, in the event that such a vehicle is not available, a
        reasonable alternative vehicle) within any time for so doing given by
        the Company or within a reasonable time.
      </p>
      <p>
        2.4.3. In providing the Passenger Services, a Driver shall choose, at
        his/her sole discretion, the route from the Collection Address to the
        Customer’s destination. If a Customer requests that a specific route is
        taken, any price quoted in respect of such journey may be amended.
      </p>
      <p>
        2.4.4. Customers must inform the Company at the time of making a booking
        if the Customer or any Passenger wishes to carry any domestic animals in
        any Passenger Vehicle. All domestic animals should be carried in a
        suitable box or cage, if appropriate and/or be suitably restrained. The
        Company and/or the Driver reserve the right to cancel a booking on
        arrival if the Company has not been informed of the Customer’s
        requirement to carry an animal in the Passenger Vehicle. Guide dogs are
        exempt from this requirement and are permitted to be carried in any
        Passenger Vehicle.
      </p>
      <p>
        2.4.5. Passengers are not permitted to smoke in any Passenger Vehicle.
      </p>
      <p>
        2.4.6. Passengers shall not play any musical instrument or recorded
        music in any Passenger Vehicle except with the written permission of the
        Company.
      </p>
      <p>
        2.4.7. Passengers shall not consume alcohol in any Passenger Vehicle
        except with the written permission of the Company and the Company
        reserves the right to decline carriage to any Passenger who, in its
        opinion, is intoxicated.
      </p>
      <p>
        2.4.8. The transportation of luggage in a Passenger Vehicle shall be
        permitted in the absolute discretion of the Company. Passengers shall
        remain responsible at all times for their personal luggage. The Company
        may assist the Customer with the loading and unloading of his/her
        luggage from the Passenger Vehicle, at the Company’s sole discretion.
      </p>
      <p>
        2.4.9. The Company accepts no responsibility for the loss or damage to
        any luggage which is transported in a Passenger Vehicle. The Customer
        acknowledges and accepts that any luggage stored in the Passenger
        Vehicle may move around during the journey and accordingly the Customer
        (and any Passengers) should take extra care when opening the luggage
        compartment of the Passenger Vehicle.
      </p>
      <p>
        2.4.10. Passengers are required to comply with current customs laws and
        regulations and the Company shall not be responsible for any delays
        caused by any failure to comply with the same.
      </p>
      <p>
        <b>
          2.4.11. All Passengers are required to use seatbelts at all times.
        </b>
      </p>
      <p>
        2.4.12. The Company will allow unaccompanied children of 14 years of age
        or less to travel alone in a Passenger Vehicle at its own discretion.
        When booking a journey for any child less than 14 years of age the
        caller must inform the Company that a child will be traveling. The
        Company does not accept any additional responsibility for any child who
        travels unaccompanied in a Passenger Vehicle. Please note child seats
        are not a requirement in Taxi &amp; Private Hire Vehicles, and for this
        reason we do not enforce or provide these while undertaking a journey
        with us; you can bring your own seat, but this cannot be stored with the
        driver.
      </p>
      <p>
        2.4.13. The Company reserves the right to refuse to transport any
        Passenger who behaves in a disorderly, threatening or abusive manner or
        who, in its absolute discretion, the Company considers a nuisance or a
        danger to its employees, agents, subcontractors or to fellow Passengers.
        The Company is committed to providing services in accordance with the
        Equality Act. The Company will do what it reasonably can to assist those
        who are not capable of boarding and alighting a Passenger Vehicle
        unaided.
      </p>
      <p>
        2.4.14.&nbsp;
        <b>
          The Company reserves the right to charge reasonable cleaning charges
          plus 2h loss of earnings for the driver at our normal hourly rate in
          the event of spillages, vomits or other damages to a car interior. For
          any spillages Company reserves the right to charge £10 per every
          single seat and in event that any Passenger do (or is directly or
          indirectly responsible for) any other serious mess (vomits, dog hairs,
          food related mess, mud on seats and similar) £30 per seat plus 2h loss
          of earnings. 1h loss of earnings equals £20. In case of any serious
          damages, Passengers takes full responsibility to cover all costs to
          restore the car to the usable condition and this include the loos of
          earnings to the driver.
        </b>
      </p>
      <p>
        2.4.15. Any dates, periods or times specified by the Company in
        connection with the performance of the Passenger Services are estimates
        only and time shall not be of the essence for the performance by the
        Company of its obligations under the Contract. The Company makes no
        warranty that the Customer or Customer’s Goods or property shall be
        delivered within the Customer’s stipulated time period (if any) and/or
        within any time period stated by the Company unless expressly agreed in
        writing by a director of the Company.
      </p>
      <p>
        2.4.16. The Company shall not be responsible for any property left by
        Passengers in any Passenger Vehicle. Such property will be stored by the
        Company for a period of 14 days and thereafter the Company shall be
        entitled to return, destroy or otherwise dispose of such property as it,
        in its absolute discretion, sees fit.
      </p>
      <p>
        2.4.17. The Passenger shall be responsible for covering the cost of
        extra charges in the event of a delay being caused by a 3rd party
        (immigration or luggage claim queues, lost property or delays caused by
        the airport/airline used).
      </p>
      <p>
        2.4.18.&nbsp;
        <b>
          A maximum time of 15 minutes for normal bookings and 60 minutes for
          airport/port bookings
        </b>
        &nbsp;will be allocated (when the booking was prepaid), after which time
        non-contact with Customer will classify the booking to be a “no-show”.
        For the purpose hereof, a “No-show” is defined as an event whereby a
        Customer (a) in case of address collection, fails to meet the driver on
        the agreed pick-up time at the agreed pick-up point; or (b) in case of
        airport/port collection, fails to meet the driver in the arrival hall
        within the allocated time.
      </p>
      <p>
        2.4.19. The Company uses Google Maps to improve the Website user
        experience for our Customers. However, the Company does not assume any
        responsibility for the maps accuracy, distance or time displayed by
        Google Maps. This is a third party service. If the Customer wants to
        know the exact distance, the average time of a journey or other
        information displayed by Google Maps, they should call our Operating
        Centre.
      </p>
      <p>
        2.4.20. The Company is committed to protecting the privacy of its
        Customers. The Company will only use the information that it collects
        about the Customers lawfully (in accordance with the Data Protection Act
        1998). Information is collected for two reasons: first, to process the
        reservation, and second, to provide the Customer with the best possible
        service. The Company will not send out marketing e-mail in the future
        unless so consented by the Customer. The Company will give the Customer
        the chance to refuse any marketing email from the Company or from
        another trader in the future. The type of information the Company will
        collect about the Customer includes their name, address, phone number,
        email address and credit/debit card details. The Company will never
        collect sensitive information about the Customer without their explicit
        consent. The information held by the Company will be accurate and up to
        date. The Customer will be able to check the information held by the
        Company by emailing the same. If the Customer finds any inaccuracies,
        the Company will delete or correct it promptly.
      </p>
      <p>
        2.4.21.&nbsp;
        <b>
          The Customer shall be liable for any damage caused by Passengers to
          any Passenger Vehicle.
        </b>
      </p>
      <p>
        2.4.22. Except in respect of death or personal injury caused by the
        Company’s negligence, or that of its servants or agents, the Company’s
        liability to the Customer for loss and/or damage caused by the
        negligence of the Company and/or its servants or agents, or otherwise
        which arises out of or in connection with the provision of the Services
        or their use by the Customer shall be limited as follows:
      </p>
      <p>
        2.4.22.1. In relation to the Services generally, the Company’s liability
        shall not exceed £150;
      </p>
      <p>
        2.4.22.2. In the case of lost or damaged Goods (including where relevant
        luggage of Customers traveling in Passenger Vehicles), the Company’s
        liability shall not exceed £150 unless the Customer has notified the
        Company that the Goods have a value in excess of £150 before the journey
        and the Company has agreed in writing to be responsible for the repair
        or replacement of the Goods up to a greater value and the Customer shall
        indemnify the Company against any Passenger claiming sums in excess of
        such limits.
      </p>
      <p>
        2.4.23. Any complaints relating to the Services shall be addressed to
        the Company and made in writing within 7 days of the event giving rise
        to the complaint.
      </p>
      <p>
        <b>CHARGES AND FEES</b>
      </p>
      <p>
        <b>3.1. Car Type</b>
      </p>
      <p>We provide services using the following car types:</p>
      <p>Saloon (1-3 passengers / max. 2 check in / 2 hand luggage)</p>
      <p>(Toyota Prius, Skoda or similar)</p>
      <p>Executive (1-3 passengers / max. 2 check in / 2 hand luggage)</p>
      <p>(Mercedes E-class, BMW 5 Series or similar)</p>
      <p>Estate (1-4 passengers / max. 3 check in / 2 hand luggage)</p>
      <p>(Mercedes E-class Estate, Toyota Auris or similar)</p>
      <p>MPV (1-4 passengers / max. 4 check in / 4 hand luggage)</p>
      <p>(Citroen C4, Seat Alhambra, VW Sharan or similar)</p>
      <p>MPV5 (1-5 passengers / max. 5 check in / 2 hand luggage)</p>
      <p>(Ford Galaxy or similar)</p>
      <p>MPV6 (1-6 passengers / max. 6 check in / 6 hand luggage)</p>
      <p>(Mercedes Vito, Vauxhall Vivaro or similar)</p>
      <p>
        MPV7/8 Minibus (1-7/8 passengers / max. 7/8 check in / 7/8 hand luggage)
      </p>
      <p>(VW T5, Vauxhall Vivaro or similar)</p>
      <p>MPV Executive (1-7 passengers / max. 5 check in / 4 hand luggage)</p>
      <p>(Mercedes Vclass, Vito or similar)</p>
      <p>VIP (1-3 passengers / max. 2 check in / 2 hand luggage)</p>
      <p>(Mercedes Sclass or similar)</p>
      <p>
        <b>3.2. Waiting Time</b>
      </p>
      <p>
        3.2.1 Extra waiting time is calculated based on scheduled pickup time
        (for local pickups) and actual landing time (for airport pickups). Based
        on the information provided by the passengers we calculate extra waiting
        time accordingly to the scheduled pickup time and for airport pickups
        based on actual landing time – for example: if customer requested to be
        picked up at 14:00pm where scheduled landing time is at 13:30pm – it
        means our driver shall be inside the terminal 30 minutes after landing
        time. Driver shall monitor the flights and enter the terminal
        accordingly to actual landing time. We can not promise that in case of
        early landing the driver will be there earlier than original scheduled
        pickup time.
      </p>
      <p>
        3.2.2. Delays in the air. For the airport pickups if the passenger
        flight is delayed for more than 90 minutes and allocated driver is on
        the way to, or at pickup location – extra waiting charges will be
        applied for the delays in the air. First 90 minutes of the delay in the
        air will be free of charge as and therefore charges will be applied.
      </p>
      <p>3.2.3. Waiting charges:</p>
      <p>Regular address bookings</p>
      <p>0-15 minutes = free of charge / payable if exceeded £5</p>
      <p>+ every next started 15minutes – £5 + any parking (if applicable)</p>
      <p>Airport &amp; port bookings</p>
      <p>0-30 minutes = free of charge</p>
      <ul>
        <li>
          every next started 15minutes – £5.00 for Saloon / Estate / MPV + any
          parking (anything above £10)
        </li>
        <li>
          every next started 15minutes – £10.00 for Executive / MPV8 / MPV
          Executive / VIP + any parking (anything above £10)
        </li>
      </ul>
      <p>Train station bookings</p>
      <p>0-15 minutes = free of charge / payable if exceeded £5</p>
      <p>+ every next started 15minutes – £5 + any parking (if applicable)</p>
      <p>Meet &amp; Greet option is charged £10.00 extra</p>
      <p>
        <b>3.3. Other Charges</b>
      </p>
      <p>
        Diversion or stop on the way = £5.00 / diversion (depending on distance)
      </p>
      <p>Extra 15 mins waiting / parking = £5.00 for Saloon / Estate / MPV</p>
      <p>
        Extra 15 mins waiting / parking = £10.00 for Executive / MPV7/8 / MPV
        Executive / VIP
      </p>
      <p>
        Please note child seats are not a requirement in Taxi &amp; Private Hire
        Vehicles, and for this reason we do not enforce or provide&nbsp;these
        while undertaking a journey with us; you can bring your own seat, but
        this cannot be stored with the driver.
      </p>
      <p>
        <b>3.4. Public Holidays Charges / Out of hours</b>
      </p>
      <p>
        The following days will be charged at holiday rate by the Company for
        any services provided:
      </p>
      <p>
        Christmas period – between 18:00 on 24/12 up to 06:00 on 27/12 each year
      </p>
      <p>
        New Years period – between 18:00 on 31/12 up to 06:00 on 02/01 each year
      </p>
      <p>
        Easter Period – between 18:00 on Ester Friday up to 23:59pm on Easter
        Monday each year
      </p>
      <p>
        Which means the journey will be charged at 50% extra compared to the
        normal rate (ex. charge of journey + 50% holiday fee).
      </p>
      <p>
        The following days will be charged at out of hours rate rate by the
        Company for any services provided:
      </p>
      <p>21:00pm up to 04:59am every day of the week.</p>
      <p>
        Which means the journey will be charged at 20% extra compared to the
        normal rate (ex. charge of journey + 20% out of hours fee).
      </p>
      <p>
        We reserve the right to increase fares accordingly to our availability.
        If by any reason we have limited access to our driver partners, fares
        might be increased accordingly without any notice.
      </p>
      <p>
        <b>3.5. Accepted Standard Payment Method</b>
      </p>
      <p>
        Please note that all online reservations are subject to card payment
        only (the company reserves the right to accept cash payment at its own
        discretion, but reservations can only be made with the assistance of an
        operator, via phone or email). This enforcement is now in place due to
        avoid “no shows” and fake reservations. If for any reason you need to
        cancel the booking, payment will be refunded according to our standard
        cancellation and refund policy.
      </p>
      <p>
        <b>3.7. Traveling with Pets within the Vehicle</b>
      </p>
      <p>
        Transport for London enforces that&nbsp;
        <b>
          all licensed London taxis and minicabs offer their services to guide
          dog users
        </b>
        &nbsp;without extra charging them. If a company or a driver has refused
        to undertake a journey or has charged you extra because of a guide dog,
        you can report them directly to the Transport for London Taxi and
        Minicab division.&nbsp;
        <b>This applies to accredited assistance dog users only</b>.
      </p>
      <p>
        <b>
          Pets are allowed within certain vehicles only, so it would be ideal to
          pre-book such a requirement
        </b>
        . We apply a flat&nbsp;<b>£10.00 charge</b>&nbsp;(or more, depending on
        special circumstances) for such occurrences and the pet must travel
        within a safe cage or secure safety lease/harness while in transit (this
        is a law requirement).
      </p>
      <p>
        <b>CANCELATION POLICY</b>
      </p>
      <p>
        <b>4. Cancelation Policy – General</b>
      </p>
      <p>
        4.1 There is a small charge for processing the payment to our customers
        for unexpected situations where a journey needs to be cancelled (as long
        as this is done with 24 hour in advance of the requested pick-up time).
        We understand that sometimes the unpredictable happens and for
        unforeseen reasons you simply can’t make it.
      </p>
      <p>
        If you would like to cancel your booking with us, you can do this by
        emailing us with your booking reference and reason of cancellation.
        Alternatively you can call our Operating Centre or simply cancel the
        booking in the customer app.
      </p>
      <p>
        Pre-paid bookings will be refunded in maximum 5 working days from the
        moment of cancellation.
      </p>
      <p>
        If a booking is cancelled less than 24 hours prior to the schedule
        pick-up time by customer, there will be a cancellation fee applied,
        which depends on the time we were informed…
      </p>
      <p>
        More than 24 hours before pick-up time = 5% charge applied / 95% refund
      </p>
      <p>Between 24 and 3 hours before pick-up time = 30% of journey price</p>
      <p>Between 3 hours and up to the pick-up time = 50% of journey price</p>
      <p>
        Once journey has commenced (driver on the way), or If you double booked
        with a different company, but failed to inform or cancel the journey
        with us, or If you have booked a journey with us, but did not turn up,
        we will require 100% of journey price.
      </p>
      <p>
        We strongly suggest to pay for the journey 24h prior to the scheduled
        pickup time to avoid any charge.
      </p>
      <p>
        <b>DATA STORAGE AND PAYMENT METHODS</b>
      </p>
      <p>
        <b>5. Data Storage</b>
      </p>
      <p>
        5.1 Due to regulation – General Data Protection Regulation (GDPR) live
        from 25 May 2018 which replaces the Data Protection Directive 95/46/EC,
        every customer which use our services agree to storage by us necessary
        information required to the booking such as Full Name, Address, Phone
        Number, IP Address and Email Address. In any case and any time Passenger
        can ask about all information we hold about them, and if required we
        will delete it from our system.
      </p>
      <p>
        5.2 We charge all our customers using safe online payment method –
        Stripe. The payment is secured in the best possible way. Your data is
        stored in a secured (not accessible even for us) data storage online. We
        initiate the charge which amount is defined in a booking confirmation
        and has been agreed by the Passenger and the Company online, by email,
        in the app or any other communication method used for the booking
        purposes. By using our services Passengers agree to be charged any
        additional charges which occurs during the journey and which haven’t
        been agreed beforehand like – extra waiting time, extra stops, excessive
        luggage, extra parking, congestion charge, extra tools, cleaning fees
        and loos of earnings to the driver – all defined in “Charges and Fees”
        section above. In case of any extra charges Passengers will be notified
        about it by the Company and payment method used to pay for the booking
        will be debited accordingly after the journey.
      </p>
      <p>
        If you have any questions, feedback or concerns about any of the above
        you can contact us via email on contact@rado-chauffeurs.com
      </p>
    </div>
  );
};

export default Terms;
