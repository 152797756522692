import React, { useCallback, useEffect } from 'react';
import Footer from '../../elements/footer/Footer';
import Navbar from '../../elements/navbar/Navbar';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  BookingForm,
  InnerContainer,
  InputLabel,
  MainContainer,
  Input,
  Button,
  ErrorMsg,
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getSessionBookingAction,
  resetError,
  updateBooking,
} from '../../features/booking/bookingSlice';
import usePrevious from '../../hooks/usePrevious';

const BookingSchema = Yup.object().shape({
  passengerName: Yup.string().required('Required'),
  passengerEmail: Yup.string().email('Invalid email').required('Required'),
  phoneNumber: Yup.string().required('Required'),
  numberOfPassengers: Yup.number()
    .min(1, 'Must be at least 1')
    .required('Required'),
  meetAndGreetName: Yup.string(),
  pickupFlightNumber: Yup.string(),
  returnDateTime: Yup.date(),
});

const Booking = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    booking,
    isLoading,
    isError,
    message,
    isUpdatingBooking,
    isErrorUpdatingBooking,
  } = useSelector((state) => state.booking);
  const {
    vehicles,
    isLoading: isLoadingVehicle,
    isError: isErrorVechicle,
    message: messageVehicle,
  } = useSelector((state) => state.vehicle);

  const prevBooking = usePrevious(booking);
  const prevIsLoading = usePrevious(isLoading);
  const prevIsUpdatingBooking = usePrevious(isUpdatingBooking);
  const prevIsErrorUpdatingBooking = usePrevious(isErrorUpdatingBooking);

  // console.log({ booking });

  useEffect(() => {
    if (
      prevIsUpdatingBooking &&
      !isUpdatingBooking &&
      !isErrorUpdatingBooking &&
      booking?.passengerName
    ) {
      navigate('/trip');
    }
  }, [
    booking,
    isErrorUpdatingBooking,
    isUpdatingBooking,
    navigate,
    prevIsUpdatingBooking,
  ]);

  const getSessionBooking = useCallback(() => {
    dispatch(getSessionBookingAction());
  }, [dispatch]);

  useEffect(() => {
    getSessionBooking();
  }, [getSessionBooking]);

  useEffect(() => {
    if (
      (isError && message === 'no session or session expired') ||
      (prevIsLoading && isError && !booking?._id)
    ) {
      navigate('/');
    }
  }, [message, navigate, isError, prevIsLoading, booking?._id]);

  useEffect(() => {
    return () => {
      dispatch(resetError());
    };
  }, [dispatch]);

  if (booking?._id)
    return (
      <>
        <Navbar />
        <MainContainer>
          <InnerContainer>
            <h1> Passengers and flight details</h1>
            <Formik
              initialValues={{
                passengerName: booking.passengerName || '',
                passengerEmail: booking.passengerEmail || '',
                phoneNumber: booking.phoneNumber || '',
                numberOfPassengers: booking.numberOfPassengers || '',
                meetAndGreetName: booking.meetAndGreetName || '',
                pickupFlightNumber: booking.pickupFlightNumber || '',
                returnDateTime: booking.returnDateTime?.split('.')[0],
                returnFlightNumber: booking.pickupFlightNumber || '',
              }}
              validationSchema={BookingSchema}
              onSubmit={(values, { setSubmitting }) => {
                dispatch(updateBooking(values));
                setSubmitting(false);
              }}
            >
              {({ isSubmitting, errors, values }) => {
                //  console.log({ errors, values });

                return (
                  <BookingForm as={Form}>
                    <h3>Main Passenger</h3>
                    <InputLabel htmlFor="passengerName">
                      Name and Surname
                    </InputLabel>
                    <Field type="text" name="passengerName" as={Input} />
                    <ErrorMessage name="passengerName" component={ErrorMsg} />

                    <InputLabel htmlFor="passengerEmail">
                      Email* (Please double check if correct)
                    </InputLabel>
                    <Field type="email" name="passengerEmail" as={Input} />
                    <ErrorMessage name="passengerEmail" component={ErrorMsg} />

                    <InputLabel htmlFor="phoneNumber">
                      Phone number (for non-uk number please include the country
                      code)
                    </InputLabel>
                    <Field type="text" name="phoneNumber" as={Input} />
                    <ErrorMessage name="phoneNumber" component={ErrorMsg} />

                    <h3>Passengers</h3>
                    <InputLabel htmlFor="numberOfPassengers">
                      How many passengers? (including children and infants)
                    </InputLabel>
                    <Field type="number" name="numberOfPassengers" as={Input} />
                    <ErrorMessage
                      name="numberOfPassengers"
                      component={ErrorMsg}
                    />

                    <h3>Airport Transfer</h3>
                    <InputLabel htmlFor="meetAndGreetName">
                      What is the name for meet and greet table?
                    </InputLabel>
                    <Field type="text" name="meetAndGreetName" as={Input} />
                    <ErrorMessage
                      name="meetAndGreetName"
                      component={ErrorMsg}
                    />
                    <InputLabel htmlFor="pickupFlightNumber">
                      Your flight number
                    </InputLabel>
                    <Field type="text" name="pickupFlightNumber" as={Input} />
                    <ErrorMessage
                      name="pickupFlightNumber"
                      component={ErrorMsg}
                    />
                    {values?.returnDateTime && (
                      <>
                        <h3>Your return details</h3>
                        <InputLabel htmlFor="returnDateTime">
                          Return date & time
                        </InputLabel>
                        <Field
                          value={values.returnDateTime}
                          type="datetime-local"
                          name="returnDateTime"
                          as={Input}
                        />
                        <ErrorMessage
                          name="returnDateTime"
                          component={ErrorMsg}
                        />
                        <InputLabel htmlFor="returnFlightNumber">
                          Return flight number
                        </InputLabel>
                        <Field
                          type="text"
                          name="returnFlightNumber"
                          as={Input}
                        />
                        <ErrorMessage
                          name="returnFlightNumber"
                          component={ErrorMsg}
                        />
                      </>
                    )}
                    <Button type="submit" disabled={isSubmitting}>
                      To payment
                    </Button>
                  </BookingForm>
                );
              }}
            </Formik>
          </InnerContainer>
          <Footer />
        </MainContainer>
      </>
    );

  return null;
};

export default Booking;
