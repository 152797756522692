import axios from 'axios';

const API_URL = '/api/user';

const login = async (loginDetails) => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   };

  const response = await axios.post(API_URL + '/login', loginDetails);

  return response.data;
};

const register = async (userDetails) => {
  const response = await axios.post(API_URL + '/register', userDetails);

  return response.data;
};

const cancelPayment = async (paymentIntent) => {
  const response = await axios.post(API_URL + '/cancelPayment', {
    paymentIntent,
  });

  return response.data;
};

const userService = {
  login,
  register,
  cancelPayment,
};

export default userService;
