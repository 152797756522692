import { Document, Page, View, Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
  },
  section: {
    padding: 16,
    borderBottom: '1px solid lightgray',
  },
  divider: {
    marginBottom: 8,
    borderBottom: '1px solid lightgray',
  },
  paragraph: {
    fontSize: 12,
    color: '#333',
    marginBottom: 8,
  },
  header: {
    fontSize: 20,
    color: '#BC902D',
    marginBottom: 16,
  },
  subheader: {
    fontSize: 16,
    color: '#333',
    marginBottom: 8,
  },
});

const Invoice = ({ booking }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.header}>Rado Chauffeurs LTD</Text>
        <Text style={styles.paragraph}>Company number 11840133</Text>
        <Text
          style={styles.paragraph}
        >{`Registered address: 42 High Street, Watlington, England, OX49 5PY`}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.subheader}>{`Invoice`}</Text>
        <Text style={styles.paragraph}>{`Id: ${booking._id.slice(0, 8)}`}</Text>
        <Text style={styles.paragraph}>{`Date: ${new Date(
          booking.bookingDate
        ).toLocaleDateString()}`}</Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.subheader}>Booking details</Text>
        <Text
          style={styles.paragraph}
        >{`Passenger name: ${booking.passengerName}`}</Text>
        <Text
          style={styles.paragraph}
        >{`Email: ${booking.passengerEmail}`}</Text>
        <Text style={styles.paragraph}>{`From: ${booking.fromAddress}`}</Text>
        <Text style={styles.paragraph}>{`To: ${booking.toAddress}`}</Text>
        <Text style={styles.paragraph}>{`Miles: ${booking.miles.toFixed(
          2
        )}`}</Text>
        <Text
          style={styles.paragraph}
        >{`Number of passengers: ${booking.numberOfPassengers}`}</Text>
        <Text style={styles.paragraph}>{`Price: £${booking.price}`}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.subheader}>Contact us</Text>
        <Text style={styles.paragraph}>Email: support@rado-chauffeurs.com</Text>
        <Text style={styles.paragraph}>Whatsapp: +44 7828 522292</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.paragraph}>Thank you for booking with us!</Text>
        <Text style={styles.paragraph}>
          For any enquiries please contact us using the details above.
        </Text>
      </View>
    </Page>
  </Document>
);

export default Invoice;
