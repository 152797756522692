const pricesData = require('./prices.json');

function matchPrice(type, distance) {
  let price = 0;

  pricesData.prices?.forEach((e) => {
    if (distance >= e.distance[0] && distance <= e.distance[1]) {
      price = e[type];
    }
  });

  return price * distance;
}

export default matchPrice;
