import React from 'react';
import { WhyAnswer, WhyChooseUs, WhyChooseUsSection } from '../../styles/Home';

const WhyUs = () => {
  return (
    <>
      <WhyChooseUsSection>
        <h2>why choose us</h2>
        <WhyChooseUs>
          <WhyAnswer>
            <h3>Outstanding customer service</h3>
            <p>
              Bespoke service combined with meticulous attention to detail and
              personalised approach creates the ultimate luxury chauffeur
              experience in London. We are happy to liaison with your PA to
              ensure your journey goes as smoothly as possible
            </p>
          </WhyAnswer>
          <WhyAnswer>
            <h3>Professionalism</h3>
            <p>
              Our chauffeurs are always dressed in suit & tie. Our vehicles are
              always washed, and cleaned prior to every job. The chauffeurs are
              always well groomed and work around the clock to ensure your
              journey is as pleasant and hassle-free as possible.
            </p>
          </WhyAnswer>
          <WhyAnswer>
            <h3>Confidentiality</h3>
            <p>
              Our chauffeurs are hand-picked and vetted, selected for the
              outstanding customer support they provide, and have NDAs signed to
              ensure your confidentiality and utter comfort. As we believe our
              chauffeurs represent our clients, we ensure their appearance is
              maintained to the highest standards.
            </p>
          </WhyAnswer>
          <WhyAnswer>
            <h3>Luxury car experience</h3>
            <p>
              We place emphasis on luxury, sophistication and the client’s
              comfort. Our vehicles are less than 3 years old, regularly
              serviced and equipped with executive packages, on-board Wi-Fi and
              complimentary water. Alcohol service is available upon request. .
            </p>
          </WhyAnswer>
        </WhyChooseUs>
      </WhyChooseUsSection>
    </>
  );
};

export default WhyUs;
