import styled from 'styled-components';

const FooterOuter = styled.footer`
  width: 100%;
  background-color: #333;
  color: #fff;
  h2 {
    font-family: 'Saira Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 90%;
    text-transform: uppercase;
    color: white;
    margin-bottom: 8px;
  }
  & .emphased {
    color: #bc902d;
  }

  @media (min-width: 768px) {
    font-size: 36px;
    line-height: 110%;
  }
`;

const FooterInner = styled.div`
  padding: 32px 16px;
  max-width: 1120px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  .terms-container {
    text-align: end;
    a {
      color: white;
    }
  }

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  }

  & .widget {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 160px;
  }

  @media (min-width: 768px) {
    padding: 64px 16px;
    font-size: 36px;
    line-height: 110%;
  }
`;

const SocialIcons = styled.div`
  display: 'flex';
  flex-direction: row;
`;

const SocialIcon = styled.img`
  width: 24px;
  height: auto;
  margin-left: 16px;
  cursor: pointer;

  @media (min-width: 768px) {
    width: 48px;
  }
`;

export { FooterOuter, FooterInner, SocialIcons, SocialIcon };
