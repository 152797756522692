import { configureStore } from '@reduxjs/toolkit';
import bookingSlice from '../features/booking/bookingSlice';
import vehicleSlice from '../features/vehicle/vehicleSlice';
import paymentSlice from '../features/payment/paymentSlice';
import userSlice from '../features/user/userSlice';

const store = configureStore({
  reducer: {
    booking: bookingSlice,
    vehicle: vehicleSlice,
    payment: paymentSlice,
    user: userSlice,
  },
});

export default store;
