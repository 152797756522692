import { DashboardContainer, DashboardMain } from '../styles';
import AdminNav from './AdminNav';
const DashboardAdminHOC = ({ children, activePage }) => {
  return (
    <DashboardContainer>
      <DashboardMain>
        <AdminNav activePage={activePage} />
        {children}
      </DashboardMain>
    </DashboardContainer>
  );
};

export default DashboardAdminHOC;
