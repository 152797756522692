import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SideBar, SideBarButton, SideBarSection } from '../styles';
import { logOut } from '../../../features/user/userSlice';
import { useNavigate } from 'react-router-dom';

const UserNav = ({ activePage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAdmin } = useSelector((state) => state.user);

  const handleLogout = () => {
    dispatch(logOut());
    navigate('/login');
  };

  return (
    <SideBar>
      <SideBarSection>
        <SideBarButton
          isLast
          isActive={activePage === 'bookings'}
          onClick={() => navigate(`/${isAdmin ? 'admin' : 'user'}-dashboard`)}
        >
          Bookings
        </SideBarButton>
        {/* <SideBarButton isLast isActive={activePage === 'help'}>
          Help
        </SideBarButton> */}
      </SideBarSection>
      <SideBarSection>
        {/* <SideBarButton isActive={activePage === 'profile'}>
          My Profile
        </SideBarButton> */}
        <SideBarButton isLast onClick={() => handleLogout()}>
          Logout
        </SideBarButton>
      </SideBarSection>
    </SideBar>
  );
};

export default UserNav;
