import { DashboardContainer, DashboardMain } from '../styles';
import UserNav from './UserNav';
const DashboardHOC = ({ children, activePage }) => {
  return (
    <DashboardContainer>
      <DashboardMain>
        <UserNav activePage={activePage} />
        {children}
      </DashboardMain>
    </DashboardContainer>
  );
};

export default DashboardHOC;
