import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import paymentService from './paymentService';

const initialState = {
  clientSecret: '',
  cancelledIntent: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  total: 0,
};

// Create new payment
export const newPayment = createAsyncThunk(
  'payment/newPayment',
  async (paymentDetails, thunkAPI) => {
    try {
      return await paymentService.newPayment(paymentDetails);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Cancel payment
export const cancelPayment = createAsyncThunk(
  'payment/cancelPayment',
  async (paymentIntent, thunkAPI) => {
    try {
      return await paymentService.cancelPayment(paymentIntent);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setTotal: (state, action) => (state.total = action.payload),
    reset: (state) => initialState,
  },

  extraReducers: (builder) => {
    builder
      .addCase(newPayment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(newPayment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.clientSecret = action.payload.clientSecret;
      })
      .addCase(newPayment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(cancelPayment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(cancelPayment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.cancelledIntent = action.payload;
      })
      .addCase(cancelPayment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset, setTotal } = paymentSlice.actions;
export default paymentSlice.reducer;
