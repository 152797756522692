import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaPhone, FaWhatsapp, FaRegEnvelope } from 'react-icons/fa';
import {
  ContactBar,
  LogoSmall,
  NavbarContainer,
  NavbarLogo,
  NavbarMenu,
  NavbarMenuItem,
} from './styles';
import { openInNewTab } from '../../utils/utils';

const Navbar = ({ logo }) => {
  const [showContact, setShowContact] = useState(false);
  const location = useLocation();

  return (
    <>
      <NavbarContainer>
        <NavbarLogo
          src="/images/rado_chauffurs_logo_final.png"
          alt="rado-chauffeurs"
        />
        <LogoSmall src="/images/logo-small.png" alt="rado-chauffeurs" />
        <NavbarMenu>
          <NavbarMenuItem isMobileVisible>
            <Link to="/">Home</Link>
          </NavbarMenuItem>
          {/* <NavbarMenuItem>
            <a href="#book">Book</a>
          </NavbarMenuItem> */}
          <NavbarMenuItem isMobileVisible>
            <div onClick={() => setShowContact(!showContact)}>Contact</div>
          </NavbarMenuItem>
          <NavbarMenuItem>
            <a href="/#fleet">Fleet</a>
          </NavbarMenuItem>
          <NavbarMenuItem>
            <Link to="/help">Help</Link>
          </NavbarMenuItem>
          <NavbarMenuItem
            isMobileVisible
            selected={location.pathname === '/login'}
          >
            <Link to="/login">Login</Link>
          </NavbarMenuItem>
        </NavbarMenu>
      </NavbarContainer>
      <ContactBar>
        {showContact && (
          <div className="socials">
            <div
              className="mail"
              onClick={() => openInNewTab('mailto:contact@rado-chauffeurs.com')}
            >
              <FaRegEnvelope />
            </div>
            <div
              className="phone"
              onClick={() => openInNewTab('tel:+447828522292')}
            >
              <FaPhone />
            </div>
            <div
              className="whatsapp"
              onClick={() => openInNewTab('https://wa.me/+447828522292')}
            >
              <FaWhatsapp />
            </div>
          </div>
        )}
      </ContactBar>
    </>
  );
};

export default Navbar;
