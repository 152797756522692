import React from 'react';
import { Ellipsis, PageButton } from './styles';

function PaginationComponent({ currentPage, totalPages, handlePageChange }) {
  // Calculate the range of pages to display
  let startPage = Math.max(currentPage - 2, 1);
  let endPage = Math.min(currentPage + 2, totalPages);

  // Adjust the range if it's too close to the beginning or end
  if (endPage - startPage < 4) {
    if (startPage === 1) {
      endPage = Math.min(startPage + 4, totalPages);
    } else {
      startPage = Math.max(endPage - 4, 1);
    }
  }

  // Generate an array of page numbers within the range
  const pageNumbers = Array.from(
    { length: endPage - startPage + 1 },
    (_, i) => startPage + i
  );

  return (
    <div className="pagination" style={{ margin: '16px auto' }}>
      {/* Previous page PageButton */}
      {currentPage > 1 && (
        <PageButton onClick={() => handlePageChange(currentPage - 1)}>
          Previous
        </PageButton>
      )}

      {/* First page link */}
      {startPage > 1 && (
        <PageButton onClick={() => handlePageChange(1)}>1</PageButton>
      )}

      {/* ... (ellipsis) if there are skipped pages */}
      {startPage > 2 && <span>...</span>}

      {/* Page numbers */}
      {pageNumbers.map((page) => (
        <PageButton
          key={page}
          onClick={() => handlePageChange(page)}
          active={currentPage === page ? 'active' : ''}
        >
          {page}
        </PageButton>
      ))}

      {/* ... (ellipsis) if there are skipped pages */}
      {endPage < totalPages - 1 && <Ellipsis>...</Ellipsis>}

      {/* Last page link */}
      {endPage < totalPages && (
        <PageButton onClick={() => handlePageChange(totalPages)}>
          {totalPages}
        </PageButton>
      )}

      {/* Next page PageButton */}
      {currentPage < totalPages && (
        <PageButton onClick={() => handlePageChange(currentPage + 1)}>
          Next
        </PageButton>
      )}
    </div>
  );
}

export default PaginationComponent;
