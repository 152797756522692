import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBookingsAction } from '../../../features/booking/bookingSlice';
import PaginationComponent from '../Pagination';
import DashboardAdminHOC from './DashboardAdminHOC';
import UserBookingAdmin from './UserBookingAdmin';

const UserBookings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { bookings, gettingAllBookings } = useSelector(
    (state) => state.booking
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    dispatch(getAllBookingsAction({ currentPage, itemsPerPage: 20 }));
  }, [currentPage, dispatch]);

  useEffect(() => {
    if (bookings?.pages) {
      setTotalPages(bookings.pages);
    }
  }, [bookings?.pages]);

  const handleRowClick = (bookingId) => {
    navigate(`/admin-dashboard/booking/${bookingId}`);
  };

  if (gettingAllBookings) {
    return <div>Loading...</div>;
  }

  if (id && bookings?.results.length > 0) {
    return (
      <DashboardAdminHOC activePage={'bookings'}>
        <UserBookingAdmin
          booking={bookings?.results.find((booking) => booking._id === id)}
        />
      </DashboardAdminHOC>
    );
  }

  if (bookings && bookings.results?.length > 0)
    return (
      <DashboardAdminHOC activePage={'bookings'}>
        <Container>
          <Heading>Admin Bookings</Heading>
          <BookingsTable>
            <thead>
              <TableRow>
                <TableHeader>ID</TableHeader>
                <TableHeader>Booking Date</TableHeader>
                <TableHeader>From</TableHeader>
                <TableHeader>To</TableHeader>
                <TableHeader>Miles</TableHeader>
                <TableHeader>Price</TableHeader>
                <TableHeader>Passengers</TableHeader>
                <TableHeader>Is Paid</TableHeader>
              </TableRow>
            </thead>
            <tbody>
              {bookings.results?.map((booking) => (
                <TableRow
                  key={booking._id}
                  onClick={() => handleRowClick(booking._id)}
                >
                  <TableCell>{booking._id.substring(0, 6)}</TableCell>
                  <TableCell>
                    {new Date(booking.createdAt).toLocaleDateString()}
                  </TableCell>
                  <TableCell>{booking.fromAddress}</TableCell>
                  <TableCell>{booking.toAddress}</TableCell>
                  <TableCell>{booking.miles?.toFixed(2)}</TableCell>
                  <TableCell>{booking.price} GBP</TableCell>
                  <TableCell>{booking.numberOfPassengers}</TableCell>
                  <TableCell>{booking.isPaid ? 'Yes' : 'No'}</TableCell>
                </TableRow>
              ))}
            </tbody>
          </BookingsTable>
          {/* <Pagination>
          <Button onClick={handlePrevPage} disabled={currentPage === 1}>
            Prev
          </Button>
          <span>
            {currentPage} / {totalPages}
          </span>
          <Button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </Pagination> */}

          <PaginationComponent
            currentPage={currentPage}
            totalPages={bookings?.pages}
            handlePageChange={(page) => setCurrentPage(page)}
          />
        </Container>
      </DashboardAdminHOC>
    );
};

const Container = styled.div`
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
`;

const Heading = styled.h2`
  color: #333;
  margin-bottom: 20px;
  text-align: center;
`;

const BookingsTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  @media (max-width: 768px) {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f4f4f4;
  }

  &:hover {
    background-color: #e6f7ff;
    cursor: pointer;
  }
`;

const TableHeader = styled.th`
  padding: 10px;
  background-color: #f8f9fa;
  border-bottom: 2px solid #ddd;
  text-align: left;
`;

const TableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const Button = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  margin: 0 5px;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #ced4da;
    cursor: not-allowed;
  }
`;

export default UserBookings;
