import styled from 'styled-components';

const MainContainer = styled.section`
  background-color: #d9d9d9;

  padding: 32px 8px;
  @media (min-width: 768px) {
    padding: 100px 0;
  }
`;

const InnerContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 32px;
  display: grid;
  grid-row-gap: 32px;
  margin: auto;
  max-width: 1220px;

  @media (min-width: 768px) {
    padding: 32px 96px 96px;
  }

  p {
    line-height: 130%;
  }

  h1 {
    font-family: 'Saira Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 57px;
    /* identical to box height */

    text-transform: uppercase;

    color: #374151;
  }

  h2 {
    font-family: 'Saira Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 38px;
    text-transform: uppercase;
    color: #bc902d;
  }

  h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 8px;
  }
`;

const VehicleDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;

  .car-image {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Details = styled.div`
  padding: 32px;
  background-color: #f5f5f5;
  img,
  .capacity {
    margin-right: 8px;
  }
  p,
  h3 {
    margin-bottom: 16px;
  }
`;

const BookingForm = styled.div`
  display: grid;
  grid-row-gap: 16px;
`;

const InputLabel = styled.label`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`;

const Input = styled.input`
  height: 50px;
  background: #f5f5f5;
  border-radius: 5px;
  border: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding: 0px 16px;

  @media (min-width: 768px) {
    height: 50px;
  }
`;

const ListItem = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding: 0px 16px;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 205px;
  background: #bc902d;
  border-radius: 8px;
  font-family: 'Saira Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 90%;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 16px;
  margin-bottom: 16px;
  border: none;
  cursor: pointer;
`;

export const ErrorMsg = styled.div`
  color: red;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 19px;
`;

export {
  MainContainer,
  InnerContainer,
  BookingForm,
  InputLabel,
  Input,
  Button,
  ListItem,
  VehicleDetails,
  Details,
};
