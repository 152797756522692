import styled from 'styled-components';

export const Loader = styled.div`
  animation: rotate 1s infinite;
  height: 100px;
  width: 100px;
  margin: 16px auto;

  &:before,
  &:after {
    border-radius: 50%;
    content: '';
    display: block;
    height: 40px;
    width: 40px;
  }
  &:before {
    animation: ball1 1s infinite;
    background-color: #cb2025;
    box-shadow: 60px 0 0 #f8b334;
    margin-bottom: 20px;
  }
  &:after {
    animation: ball2 1s infinite;
    background-color: #00a096;
    box-shadow: 60px 0 0 #97bf0d;
  }

  @keyframes rotate {
    0% {
      -webkit-transform: rotate(0deg) scale(0.8);
      -moz-transform: rotate(0deg) scale(0.8);
    }
    50% {
      -webkit-transform: rotate(360deg) scale(1.2);
      -moz-transform: rotate(360deg) scale(1.2);
    }
    100% {
      -webkit-transform: rotate(720deg) scale(0.8);
      -moz-transform: rotate(720deg) scale(0.8);
    }
  }

  @keyframes ball1 {
    0% {
      box-shadow: 60px 0 0 #f8b334;
    }
    50% {
      box-shadow: 0 0 0 #f8b334;
      margin-bottom: 0;
      -webkit-transform: translate(30px, 30px);
      -moz-transform: translate(30px, 30px);
    }
    100% {
      box-shadow: 60px 0 0 #f8b334;
      margin-bottom: 20px;
    }
  }

  @keyframes ball2 {
    0% {
      box-shadow: 60px 0 0 #97bf0d;
    }
    50% {
      box-shadow: 0 0 0 #97bf0d;
      margin-top: -40px;
      -webkit-transform: translate(30px, 30px);
      -moz-transform: translate(30px, 30px);
    }
    100% {
      box-shadow: 60px 0 0 #97bf0d;
      margin-top: 0;
    }
  }
`;
