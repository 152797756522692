import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllUserBookingsAction,
  resetUserBookings,
} from '../../../features/booking/bookingSlice';
import UserBookings from './UserBookings';
import DashboardHOC from './DashboardHOC';
import Processing from '../../../components/Processing';
import { Link, useParams } from 'react-router-dom';
import UserBooking from './UserBooking';
import { ErrorMessage, Message } from '../styles';

const UserDashboard = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    userBookings,
    gettingAllUserBookings,
    isErrorGettingAllUserBookings,
  } = useSelector((state) => state.booking);

  useEffect(() => {
    if (!userBookings) {
      dispatch(getAllUserBookingsAction());
    }
  }, [dispatch, userBookings]);

  useEffect(() => {
    return () => {
      dispatch(resetUserBookings());
    };
  }, [dispatch]);

  if (gettingAllUserBookings) {
    return (
      <DashboardHOC activePage={'bookings'}>
        <Processing />
      </DashboardHOC>
    );
  }

  if (id && userBookings?.length > 0) {
    return (
      <DashboardHOC activePage={'bookings'}>
        <UserBooking
          booking={userBookings.find((booking) => booking._id === id)}
        />
      </DashboardHOC>
    );
  }
  return (
    <DashboardHOC activePage={'bookings'}>
      {isErrorGettingAllUserBookings && (
        <ErrorMessage>
          Sorry, we couldn't retrieve your bookings, please try again later or
          contact support.
        </ErrorMessage>
      )}
      {userBookings?.length > 0 ? (
        <UserBookings bookings={userBookings} />
      ) : (
        <>
          <Message>
            We couldn't find any bookings for this accounts. You can book your
            upcoming trip with us <Link to={'/'}>here</Link>. When booking
            please use the same email that you have used to login to this
            account. If you need any help please contact us.
          </Message>
          <Message>
            If you think this is an error, please contact support here{' '}
            <a href="mailto:contact@rado-chauffeurs.com">
              contact@rado-chauffeurs.com
            </a>{' '}
            or via whatsapp at this number: +44 7828 522292
          </Message>
        </>
      )}
    </DashboardHOC>
  );
};

export default UserDashboard;
