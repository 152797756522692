import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userService from './userService';

const initialState = {
  errorLogginIn: '',
  logginIn: false,
  errorRegister: false,
  messageErrorRegister: '',
  isRegistering: false,
  token: null,
  id: '',
  name: '',
  email: '',
  isAdmin: false,
};

// login user
export const loginAction = createAsyncThunk(
  'user/login',
  async (loginDetails, thunkAPI) => {
    try {
      return await userService.login(loginDetails);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//register

export const registerAction = createAsyncThunk(
  'user/register',
  async (userDetails, thunkAPI) => {
    try {
      return await userService.register(userDetails);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: () => initialState,
    logOut: () => {
      localStorage.removeItem('user');
      localStorage.removeItem('isAdmin');
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(loginAction.pending, (state) => {
        state.logginIn = true;
        state.errorLogginIn = false;
      })
      .addCase(loginAction.fulfilled, (state, action) => {
        state.logginIn = false;
        state.token = action.payload.token;
        state.name = action.payload.name;
        state.email = action.payload.email;
        state.id = action.payload.id;
        state.isAdmin = action.payload.isAdmin;

        localStorage.setItem('user', action.payload.token);
        localStorage.setItem('isAdmin', action.payload.isAdmin);
      })
      .addCase(loginAction.rejected, (state, action) => {
        state.logginIn = false;
        state.errorLogginIn = action.payload;
      })
      .addCase(registerAction.pending, (state) => {
        state.isRegistering = true;
        state.errorRegister = false;
      })
      .addCase(registerAction.fulfilled, (state, action) => {
        state.isRegistering = false;
      })
      .addCase(registerAction.rejected, (state, action) => {
        state.isRegistering = false;
        state.errorRegister = action.payload;
      });
  },
});

export const { reset, logOut } = userSlice.actions;
export default userSlice.reducer;
