import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import usePrevious from '../../hooks/usePrevious';
import { loginAction } from '../../features/user/userSlice';
import Navbar from '../../elements/navbar/Navbar';
import Footer from '../../elements/footer/Footer';
import Processing from '../../components/Processing';

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  padding-top: 32px;
  h1 {
    font-size: 24px;
    margin-bottom: 8px;
  }
`;

const LoginForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  gap: 8px;
  width: 320px;

  p {
    line-height: 120%;
    text-align: center;
  }
`;

const FormField = styled(Field)`
  padding: 10px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
`;

const Button = styled.button`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 220px;
  background: #bc902d;
  border-radius: 8px;
  cursor: pointer;
  font-family: 'Saira Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 90%;
  text-transform: uppercase;
  color: #ffffff;
  margin: 16px auto;
`;

const ErrorMessageWrapper = styled(ErrorMessage)`
  color: red;
`;

const RequestError = styled.p`
  color: red;
`;

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is required'),
  password: Yup.string().required('Password is required'),
});

function Login() {
  const initialValues = {
    email: '',
    password: '',
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { logginIn, errorLogginIn, isAdmin } = useSelector(
    (state) => state.user
  );

  const prevLogginIn = usePrevious(logginIn);

  useEffect(() => {
    if (prevLogginIn && !errorLogginIn) {
      if (isAdmin) {
        navigate('/admin-dashboard');
      } else {
        navigate('/user-dashboard');
      }
    }
  }, [errorLogginIn, isAdmin, navigate, prevLogginIn]);

  const handleSubmit = (values) => {
    dispatch(loginAction(values));
  };

  return (
    <>
      <Navbar />
      <LoginContainer>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ errors, touched }) => (
            <LoginForm>
              <h1>Login</h1>
              <FormField type="email" name="email" placeholder="Email" />
              <ErrorMessageWrapper name="email" component="div" />
              <FormField
                type="password"
                name="password"
                placeholder="Password"
              />
              <ErrorMessageWrapper name="password" component="div" />
              <Button type="submit">Submit</Button>
              <p>
                You don't have an account? <a href="/register">Join us!</a>
              </p>
              {errorLogginIn && <RequestError>{errorLogginIn}</RequestError>}
            </LoginForm>
          )}
        </Formik>
        {logginIn && <Processing />}
      </LoginContainer>
      <Footer />
    </>
  );
}

export default Login;
